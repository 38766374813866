@font-face {
  font-family: "TTCommons-DemiBold";
  src: url("../assets/fonts/ttcommons-demibold.woff2") format("woff2"),
    url("../assets/fonts/ttcommons-demibold.woff") format("woff"),
    url("../assets/fonts/TTCommons-DemiBold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Medium";
  src: url("../assets/fonts/ttcommons-medium.woff2") format("woff2"),
    url("../assets/fonts/ttcommons-medium.woff") format("woff"),
    url("../assets/fonts/TTCommons-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-DemiBold";
  src: url("../assets/fonts/ttcommons-demibold.woff2") format("woff2"),
    url("../assets/fonts/ttcommons-demibold.woff") format("woff"),
    url("../assets/fonts/TTCommons-DemiBold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Regular";
  src: url("../assets/fonts/ttcommons-regular.woff2") format("woff2"),
    url("../assets/fonts/ttcommons-regular.woff") format("woff"),
    url("../assets/fonts/TTCommons-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brandon_grotesquebold";
  src: url("../assets/fonts/brandon_bld.woff2") format("woff2"),
    url("../assets/fonts/brandon_bld.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "TTCommons-Regular";
  background: #fafbfd;
  overflow-x: hidden;
}
