$angle-down-arrow-img: url("../assets/svg/AngleDownArrow.svg");

// style-guid-css
* {
  box-sizing: border-box !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

h1 {
  font-family: "TTCommons-DemiBold";
  font-size: 42px;
  font-weight: normal;
  line-height: 38px;
  margin: 0;
}

h2 {
  font-family: "TTCommons-Medium";
  font-size: 30px;
  font-weight: normal;
  line-height: 31px;
  margin: 0;
}

h3 {
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: normal;
  line-height: 21px;
  margin: 0;
}

h4 {
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

h5 {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

h6 {
  font-family: "TTCommons-Regular";
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  margin: 0;
}

p {
  color: #000485;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.text-gray {
  color: #607790 !important;
}

.text-primary {
  color: #000485 !important;
}

.MuiFormHelperText-root {
  &.Mui-error {
    text-align: center;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin: 8px 0 0 0;
    color: #f44336;
  }
}

.link {
  color: #0030b5 !important;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #0030b5;
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;

  + .link {
    margin-left: 15px;
  }
}

.row {
  margin-left: -10px;
  margin-right: -10px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.text-center {
  text-align: center !important;
}

.form-group {
  margin-bottom: 15px;

  h3 {
    color: #000485;
  }
}

.MuiButton-root.btn {
  padding: 9.65px 11.45px 7.65px;
  min-width: 136px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  font-family: "TTCommons-Medium";
  border-radius: 3px;
  border: 1px solid;
  box-shadow: none !important;
  min-height: 40px;

  &.btn-active {
    background-color: #0030b5;
    border-color: #0030b5;
    color: #fff;

    &:hover {
      background-color: rgba(0, 48, 181, 0.9);
      border-color: rgba(0, 48, 181, 0.9);
      color: #fff;
    }
  }

  &.btn-activedisabled {
    background-color: rgba(0, 48, 181, 0.6);
    border-color: rgba(0, 48, 181, 0.6);
    color: #fff;
  }

  &.btn-negative {
    background-color: #8094ab;
    border-color: #8094ab;
    color: #ffffff;

    &:hover {
      background-color: rgba(128, 148, 171, 0.9);
      border-color: rgba(128, 148, 171, 0.9);
      color: #ffffff;
    }
  }

  &.btn-negativedisabled {
    background-color: rgba(128, 148, 171, 0.6);
    border-color: rgba(128, 148, 171, 0.6);
    color: #fff;
  }

  &.rounded-btn {
    border-radius: 45px !important;
    background-color: #f76155 !important;
    min-width: 145px !important;
    min-height: 30px !important;
    padding: 1px 0 0 0 !important;
    border: 0 !important;
    color: #fff !important;

    &:hover {
      opacity: 0.9;
    }
  }

  &.btn-border {
    border-color: #0030b5;
    background: transparent;
    color: #0030b5;

    &:hover {
      opacity: 0.9;
    }
  }
}

.MuiInputBase-formControl {
  .MuiInputBase-input {
    border: 2px solid #d1dde5;
    border-radius: 45px !important;
    padding: 19.15px 20px 15.15px;
    color: #000485;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: "TTCommons-Medium";
    opacity: 1;
    min-height: 60px;
    background-color: #ffffff;

    //background-color: #fafbfd;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #607790;
      opacity: 1;
      // text-align: center;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #607790;
      opacity: 1;
      // text-align: center;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #607790;
      opacity: 1;
      // text-align: center;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #607790;
      opacity: 1;
      // text-align: center;
    }

    &:-internal-autofill-selected {
      background-color: #fff !important;
      color: #000485 !important;
    }

    &.Mui-disabled {
      background-color: #f1f4f7;
      border-color: #f1f4f7;
      text-align: center;
    }
  }

  textarea.MuiInputBase-input {
    border-radius: 10px !important;
    padding: 20px;
    // text-align: left;
  }

  fieldset {
    display: none;
  }

  &.Mui-error {
    .MuiInputBase-input {
      border-color: #f76155;
    }
  }
}

.react-custom-dropdown {
  > div {
    border: 2px solid #d1dde5 !important;
    // border-radius: 45px;
    // padding: 0;
    color: #607790;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: "TTCommons-Medium";
    opacity: 1;
    min-height: 60px;
    background-color: #ffffff;
    //background-color: #fafbfd;
    box-shadow: none !important;
    width: 100%;

    // &.css-qugqry-menu {
    //   border-radius: 10px;
    //   padding: 20px !important;
    //   text-align: left !important;
    //   background-color: #ffffff;

    //   //background-color: #fafbfd;
    //   >div {
    //     right: 0 !important;

    //     >div {
    //       padding: 0 15px !important;
    //       color: #607790;
    //       font-family: "TTCommons-Medium";
    //       font-size: 15px;
    //       letter-spacing: 0;

    //       >div:first-child {
    //         margin-right: 15px;

    //         .custom-checkbox {
    //           .checkmark {
    //             overflow: visible;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // >div {
    //   padding: 0;

    //   >div {
    //     padding: 0;

    //     &.css-isfiuo-placeholder {
    //       padding-left: 8px;
    //     }
    //   }

    //   &:last-child {
    //     position: relative;
    //     right: 15px;
    //   }
    // }
  }

  &.multi-select {
    > div:last-child {
      display: none;
    }
  }
}

.selected-items-wrap {
  display: flex;
  align-items: center;
  margin: 20px 20px 0;
  justify-content: center;
  flex-wrap: wrap;

  .MuiChip-root {
    border-radius: 18.5px;
    background-color: #f1f4f7;
    padding: 7px 10px 4px;
    height: auto;
    color: #0030b5;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    font-family: "TTCommons-Regular";
    margin: 0 15px 15px 0;

    span {
      padding: 0;
    }

    svg {
      margin: -2px 0 0 8px;
      fill: #0030b5;
      opacity: 1;
      vertical-align: middle;
    }
  }
}

.custom-switch-div {
  display: flex;
  align-items: center;

  .MuiSwitch-root {
    margin: 0 auto;

    .MuiSwitch-track {
      height: 20px;
      width: 33px;
      border-radius: 10px;
      border-width: 2px;
    }
  }

  span.label {
    margin: 5px 0 0 10px;
    line-height: normal;
    font-family: "TTCommons-Medium";
    font-size: "18px";
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.checkbox-wrap {
  display: flex;

  span.label {
    margin: 0 0 0 10px;
  }
}

.custom-datepicker {
  position: relative;

  .MuiFormControl-root {
    width: 100%;
  }

  .react-datepicker__tab-loop {
    width: 100%;

    .react-datepicker-popper {
      width: 100%;
      z-index: 1;
      max-width: 301px;

      .react-datepicker {
        border: 2px solid #d1dde5;
        border-radius: 6px;
        background-color: #ffffff;
        //background-color: #fafbfd;
        padding: 20px;
        box-shadow: none;
        position: relative;

        .react-datepicker__navigation--previous {
          position: absolute;
          left: 17px;
          padding: 10px;
          font-size: 0;
          width: 15px;
          height: 15px;
          background-size: 15px 15px;
        }

        .react-datepicker__navigation--next {
          position: absolute;
          right: 17px;
          padding: 10px;
          font-size: 0;
          width: 15px;
          height: 15px;
          background-size: 15px 15px;
        }

        .react-datepicker__header {
          padding: 0;
          background: transparent;

          .react-datepicker__current-month {
            padding: 2px 0 0 0;
          }

          .react-datepicker__day-names {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0 0;
            margin: 20px 0;

            .react-datepicker__day-name {
              font-family: "TTCommons-Regular";
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;
              text-align: center;
              width: 30px;
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;

            > div {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;
              font-family: "TTCommons-Regular";
              padding: 3px 0 0 0;
            }
          }
        }
      }
    }
  }
}

.custom-timepicker {
  position: relative;

  > div {
    height: 60px !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .react-datepicker__tab-loop {
    width: 100%;

    .react-datepicker-popper {
      width: 100%;
      max-width: 200px;
      z-index: 1;

      .react-datepicker {
        border: 2px solid #d1dde5;
        border-radius: 6px;
        background-color: #ffffff;
        //background-color: #fafbfd;
        padding: 20px;
        max-height: 280px;
        overflow-y: auto;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            color: #607790 !important;
            font-family: "TTCommons-Regular";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            padding: 15px 10px 11px !important;

            &:hover {
              color: #fff !important;
              background: #000485 !important;
            }

            &.react-datepicker__time-list-item--selected {
              color: white !important;
            }
          }
        }
      }
    }
  }
}

.fileupload-custom {
  color: #607790;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  .dropzone {
    border-radius: 7.55px;
    background-color: #d1dde5;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    margin: 0 0 10px 0;

    .upload-img {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        max-height: 200px;
      }

      &.uploaded {
        img {
          width: 100%;
          height: auto;
          max-height: initial;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    video {
      height: auto;
      width: 100%;
    }
  }

  &.error {
    .dropzone {
      border: 2px solid #f44336;
    }

    .error-text {
      color: #f44336;
      margin: 5px 0 0 0;
    }
  }
}

.small-fileupload-wrap {
  .fileupload-custom {
    max-width: 171px;
    margin: 0 auto;

    .dropzone {
      margin: 30px 0 10px 0;
      height: auto;
      min-height: 100px;

      img {
        max-width: 100px;
      }

      .upload-img {
        &.placeholder {
          img {
            max-width: 94px;
          }
        }
      }
    }

    .file-name-preview {
      display: flex;
      align-items: center;
      min-height: 90px;
      white-space: normal;
      word-break: break-all;
      padding: 10px 10px;
    }
  }
}

.fileupload-custom {
  img.other-type-preview {
    padding: 20px;
    width: 240px;
  }
}

.single-select-wrap {
  h3 {
    margin: 0 0 30px 0;
    color: #000485;
  }

  .single-select-content {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0 10px;
      width: 100%;
      max-width: 260px;

      .MuiPaper-root {
        border: 2px solid #bfced8;
        border-radius: 8px;
        box-shadow: none;
        text-align: center;
        min-height: 116px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: #ffffff;
        //background-color: #fafbfd;
        cursor: pointer;

        .MuiCardContent-root {
          padding: 0;

          h6 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            font-family: "TTCommons-Medium";
          }

          p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            font-family: "TTCommons-Regular";
          }
        }

        &.selected {
          border-color: #000485;

          h6,
          p {
            color: #000485;
          }
        }
      }
    }
  }
}

.multiselect-wrap {
  h3 {
    margin: 0 0 30px 0;
    color: #000485;
  }

  .multiselect-content {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0 10px;
      width: 100%;
      max-width: 262px;

      .MuiPaper-root {
        border: 2px solid #bfced8;
        border-radius: 8px;
        box-shadow: none;
        text-align: center;
        min-height: 116px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: #ffffff;
        //background-color: #fafbfd;
        cursor: pointer;

        .MuiCardContent-root {
          padding: 0;

          h6 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            font-family: "TTCommons-Medium";
          }

          p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            font-family: "TTCommons-Regular";
          }
        }

        &.selected {
          border-color: #000485;

          h6,
          p {
            color: #000485;
          }
        }
      }
    }
  }

  &.error {
    .multiselect-content {
      > div {
        margin: 0 0 10px 0 !important;
      }
    }
  }
}

.buttons-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: 140px;
    margin: 0 15px;
  }
}

.MuiDialog-container {
  // background-color: rgba(128, 148, 171, 0.2) !important;
  background-color: transparent;
  opacity: 1 !important;

  .MuiPaper-root {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    padding: 50px;
    max-width: max-content;
    .MuiDialogTitle-root {
      padding: 0;

      h2 {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        font-family: "TTCommons-Medium";
      }
    }

    .MuiDialogContent-root {
      padding: 0;
      margin: 0px 0 25px 0;
      color: #000485;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }

    .MuiDialogActions-root {
      justify-content: space-between;
      padding: 0;

      > div {
        width: 48%;

        button {
          width: 100%;
        }
      }
    }
  }
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.radio-wrap {
  display: flex;
  align-items: center;

  .CustomRadio {
    height: 15px;
    width: 15px;
    position: relative;
    margin: -4px 8px 0 0;

    input[type="radio"] {
      height: 15px;
      width: 15px;
      opacity: 0;
      position: relative;
      z-index: 1;

      &:checked {
        + .checkmark:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #0030b5;
          border-radius: 9px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .checkmark {
      height: 15px;
      width: 15px;
      border: 2px solid #000485;
      border-radius: 9px;
      display: inline-block;
      box-sizing: border-box !important;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  > span {
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.add-button {
  button {
    border-radius: 18.5px !important;
    background-color: #f1f4f7 !important;
    color: #0030b5 !important;
    font-family: "TTCommons-Regular" !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
    min-height: 28px !important;
    padding: 5.5px 12px 3.3px 12px !important;
    min-width: 131px !important;
    border: 0 !important;
    margin: 15px 0 0 0 !important;

    i {
      font-size: 24px !important;
      font-style: normal;
      margin: 0 8px 0 0;
    }
  }

  &.mt-0 {
    button {
      margin-top: 0 !important;
    }
  }
}

.textareaGroup {
  .MuiInputBase-root {
    padding: 0;

    textarea {
      height: 100px;
    }
  }
}

.DefaultWidthcard {
  .single-select-wrap {
    .single-select-content {
      > div {
        max-width: 262px !important;

        .MuiPaper-root {
          min-height: 102px !important;
        }
      }
    }
  }
}

.job-details-card {
  .single-select-wrap {
    .single-select-content {
      margin: 0 -5px;

      > div {
        max-width: 181px !important;
        padding: 0 5px;

        .MuiPaper-root {
          min-height: 102px !important;
          padding: 0 5px;
        }
      }
    }
  }
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;
}

.PresenterDiv {
  .PresenterDiv-row {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0 10px;
      width: 100%;
      max-width: 262px;
    }
  }
}

.timeZoneselect {
  display: flex;
  align-items: flex-start;
  margin: 0 -10px;
  justify-content: center;

  .form-group {
    padding: 0 10px;
    width: 100%;
    max-width: 188px;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 14px;
    line-height: normal;
  }
}

.timezone-dropdown-wrapper {
  .dropdown-wrapper {
    .custom-dropdowninput {
      input {
        text-align: left;
        padding: 19.15px 10px 15.15px;
      }

      > span {
        right: 8px;
      }
    }
  }
}

.remove-link {
  color: #0030b5;
  font-family: "TTCommons-Regular" !important;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #0030b5;
  text-decoration: none !important;
  cursor: pointer;
}

.editor-wrap {
  max-width: 880px;
  margin: 0 auto;
}

.four-column {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;

  .form-group {
    padding: 0 10px;
    width: 100%;
    max-width: 188px;
  }
}

.typeSelectionDiv {
  border-radius: 45px;
  background-color: #f1f4f7;
  max-width: 538px;
  margin: 0 auto;
  text-align: center;
  padding: 26px 30px 22px 30px;

  h4 {
    color: #000485;
    margin: 0 0 6px 0;
  }

  h6 {
    color: #000485;
  }
}

.typeSelectionContent {
  max-width: 538px;
  margin: 0 auto;

  .form-group {
    .row {
      width: auto !important;
      margin: 0 -10px;

      > div {
        padding: 0 10px;
      }
    }
  }
}

.GuidenceContentWrapper {
  h3 {
    color: #000485;
    margin: 0 0 20px 0;
  }

  .drag-content-wrap {
    .bg-wrap {
      .drag-main-row {
        .drag-item {
          align-items: center;

          .left {
            width: 65%;

            h3 {
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              color: #000485;
            }
          }

          .DocType {
            width: 15%;
            text-align: right;

            p {
              margin: 0;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1.5px;
              line-height: 16px;
              font-family: "brandon_grotesquebold";
              color: #8094ab;
            }
          }

          .actions-div {
            width: 20%;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  + .GuidenceContentWrapper {
    margin: 30px 0 0 0px;
  }
}

.AnalyticsMapWrapper {
  h3 {
    color: #000485;
  }

  .bg-wrap {
    background-color: #f1f4f7;
    padding: 15px 15px;
    margin: 20px 0 0 0;
  }

  + .AnalyticsMapWrapper {
    margin: 50px 0 0 0;
  }
}

.MultipleFeaturescard {
  .multiselect-wrap {
    .multiselect-content {
      > div {
        max-width: 188px;
        margin: 0 0 30px 0;
      }
    }
  }
}

.OnboardingWrapper {
  padding: 60px 0;
  background: url(../assets/svg/LogIn.svg);
  background-repeat: no-repeat;
  background-position: center;

  .BrandLogo {
    max-width: 168px;
    margin: 0 auto 64px;
  }

  h1 {
    color: #000485;
    margin: 0 0 87px 0;
  }

  form {
    max-width: 325px;
    margin: 0px auto 225px;

    .form-group {
      .MuiFormControl-root {
        width: 100%;
      }
    }

    button:not(.MuiIconButton-root) {
      width: 100%;
      background-color: #f76155 !important;
      border-color: #f76155 !important;
      border-radius: 45px !important;
      height: 60px;
      font-size: 22px !important;
      margin: 0 0 30px 0;
    }
  }

  .KeepMeSignin {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 50px 0;

    .custom-checkbox {
      height: 20px;
      margin: 0 8px 0 0;
      position: relative;

      input[type="checkbox"] {
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        opacity: 0;
        z-index: 1;
      }

      .checkmark {
        height: 16px;
        width: 16px;
        border: 2px solid #ffffff;
        border-radius: 100px;
        display: inline-block;
        position: relative;

        img {
          display: none;
        }
      }

      input[type="checkbox"]:checked {
        + .checkmark {
          background: #000485;

          &:after {
            content: "";
            position: absolute;
            left: 4px;
            top: 0;
            width: 5px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .label {
      color: #000485;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }

  .forgot-password {
    a {
      color: #607790;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
      font-family: "TTCommons-Regular";
      border-bottom: 1px solid #607790;
    }
  }

  p {
    color: #000485;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0;
    font-family: "TTCommons-Medium";
    margin: 0 0 175px 0;

    a {
      border-bottom: 1.5px solid #ffffff;
      cursor: pointer;
    }
  }
}

.ForgotpwdWrapper {
  flex-direction: column !important;
}

.notificationsWrapperContent {
  .drag-wrapper {
    .drag-content-wrap {
      .bg-wrap {
        .drag-main-row {
          .drag-item {
            .left {
              width: 95%;

              h6 {
                font-size: 11px;
                letter-spacing: 1.5px;
                line-height: 16px;
                font-family: "brandon_grotesquebold";
                text-transform: uppercase;
                min-width: 110px;
                color: #000485;
                margin: 0 22px 0 0;
                max-width: 110px;
              }

              h3 {
                font-size: 18px;
                font-weight: 500;
                line-height: 19px;
              }

              .img-wrap {
                max-width: 44px;
              }
            }

            .actions-div {
              display: flex;
              width: 5%;
              justify-content: flex-end;

              .ActionSwitch {
                margin-right: 32px;
              }

              a {
                img {
                  width: auto;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  + .notificationsWrapperContent {
    margin: 15px 0 0 0;
  }
}

.SwitchText {
  display: flex;
  align-items: center;

  > div {
    margin: -4px 10px 0 0;
  }
}

.roundedCustomfileuplod {
  .fileupload-custom {
    .dropzone {
      height: 94px;
      width: 94px;
      margin: 0 auto 10px;
      border-radius: 100px;
      min-height: auto;
    }
  }
}

.DiscoverAdd {
  justify-content: center;
}

.FilterSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto 20px;

  .SearchIcon {
    min-width: 40px;
    text-align: center;
    cursor: pointer;
  }

  .filterIcon {
    cursor: pointer;
  }

  div {
    &:nth-child(2) {
      width: 100%;
      max-width: 250px;
      margin: 0 20px 0 10px;

      > div {
        width: 100%;

        input {
          padding: 0;
          height: 28px !important;
          min-height: auto;
          border-radius: 0 !important;
          border-left: 0;
          border-top: 0;
          border-right: 0;
          background: transparent;
          text-align: left;
        }
      }

      .filterIcon {
        height: 40px;
        width: 40px;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      }
    }
  }
}

.FilterBgWrap {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px 30px 20px 30px;
  max-width: 100%;
  margin: 0 auto 65px;

  .FilterTitleRow {
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: #000485;
    }

    > img {
      width: 15px;
      cursor: pointer;
    }

    .leftWrap {
      align-items: center;
      display: flex;

      .FilterList {
        color: #000485;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0 10px 0 20px;
      }
    }
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;

    > div {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }
}

.FilterResult {
  max-width: 640px;
  margin: 0 auto;
}

.ResultData {
  .ResultItem {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    padding: 20px 30px;

    .ContentDiv {
      display: flex;
      align-items: center;
      justify-content: center;

      &.maxWidth {
        max-width: calc(100% - 74px);
        width: 100%;
        margin: 0 auto;
      }

      .imgWrap {
        height: 50px;
        width: 50px;
        margin: 0 16px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f4f7;
        border-radius: 100px;
        min-width: 50px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .UserDetails {
        width: 100%;
        padding-right: 15px;

        h4 {
          color: #000485;
          font-weight: 500;
        }

        p {
          color: #000485;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 4px 0;
        }

        .flagDiv {
          display: flex;
          align-items: center;
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;

          img {
            width: 22px;
            margin: -4px 8px 0 0;
          }
        }
      }

      .ButtonWrap {
        button {
          width: 102px;
          min-width: 102px;
        }
      }
    }

    + .ResultItem {
      margin: 10px 0 0 0;
    }
  }
}

.filterTopics {
  .FilterBgWrap {
    max-width: 781px;
  }
}

.ChatFilter {
  .ChatSearch {
    margin: 0 0 12px 0px;
  }

  .ChatFilterOptions {
    .MuiPaper-root {
      box-shadow: none;
      padding: 0 !important;
      margin: 0;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      overflow: hidden;

      &:before {
        display: none;
      }

      .MuiAccordionSummary-root {
        min-height: auto;
        padding: 27px 25px 23px 25px;
        border-radius: 3px;
        background-color: #ffffff;

        .MuiAccordionSummary-content {
          margin: 0;

          .accordionHeader {
            color: #000485;
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;

            span.badge {
              height: 20px;
              width: 20px;
              border-radius: 10px;
              background-color: #f76155;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
              font-family: "TTCommons-Regular";
              color: #fff;
              padding: 4px 0 0 0px;
              font-weight: 300;
              margin: 0 0 0 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .MuiButtonBase-root {
          padding: 0;

          svg {
            fill: #0030b5;
          }
        }
      }

      + .MuiPaper-root {
        margin: 10px 0 0 0 !important;
      }
    }

    .MuiCollapse-container {
      padding: 0 0px;

      .MuiAccordionDetails-root {
        padding: 0;
      }
    }

    .chatUserItems {
      width: 100%;
      padding: 0 0px;

      .ChatItem {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        position: relative;

        .img {
          width: 50px;
          height: 50px;
          margin: 0 10px 0px 0;
          border-radius: 100px;
          text-align: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .usreDescription {
          h4 {
            color: #000485;
            margin: 0 0 3px 0;
          }

          p {
            margin: 0;
            color: #000485;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 117px;
            width: 100%;
          }
        }

        .ChatStatus {
          margin-left: auto;
          text-align: right;
          min-width: 60px;

          span.time {
            color: #566d82;
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            width: 100%;
            display: block;
            text-align: right;
          }

          span.badge {
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background-color: #f76155;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            font-family: "TTCommons-Regular";
            color: #fff;
            padding: 4px 0 0 0px;
            font-weight: 300;
            margin: 0 0 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-bottom: 6px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          top: 0;
          height: 1.5px;
          background: #e8eef2;
          width: calc(100% - 40px);
        }

        &:hover,
        &.active {
          background-color: #000485;

          &:before {
            background: #000485;
          }

          .usreDescription {
            h4,
            p {
              color: #fff;
            }
          }

          .ChatStatus {
            span.time {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.chatboxWrapper {
  .chatboxWrapperheader {
    display: flex;
    align-items: center;
    margin: 0 0 12px 0;

    h3 {
      color: #000485;
    }

    img {
      margin: 0 0 0 10px;
    }
  }
}

.Chatwrapwrapper {
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  border-radius: 3px;
  padding: 30px 16px 30px 30px;
}

.file-preview {
  margin: 15px 0 15px 25px;

  .preview-item {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    color: #f76155;
    font-family: "TTCommons-regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    color: #f76155;

    img {
      height: 15px;
      width: 15px;
      margin: -2px 10px 0 0 !important;
    }

    .progress {
      height: 6px;
      width: 150px;
      margin-left: 10px;
      border: 1px solid #f76155;
      border-radius: 5px;

      .progress-bar {
        border: 1.71px solid #f76155;
        border-radius: 5px;
        background-color: #f76155;
      }
    }

    + .preview-item {
      margin-top: 12px;
    }
  }
}

.Chatwrap {
  min-height: 711px;
  position: relative;
  padding-bottom: 80px;
  box-sizing: border-box;

  .chatmessageWrap {
    max-height: 711px;
    overflow-y: auto;
    overflow-x: hidden;

    h4 {
      color: #000485;
      margin: 0 0 28px 0;
      font-weight: 500;
    }

    .chatsItem {
      .item {
        display: flex;
        max-width: 380px;

        .user-img {
          width: 50px;
          height: 50px;
          min-width: 50px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 20px 0 0;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .userMessage {
          h4 {
            margin: 0;
            color: #000485;
            margin: 15px 0 3px 0;
          }

          span {
            color: #566d82;
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            text-align: right;
            margin-left: 8px;
          }

          p {
            color: #000485;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            margin: 0 0 8px 0;
          }
        }

        + .item {
          margin: 16px 0 0 0;
        }

        + h4 {
          margin-top: 50px;
        }

        &.rightChat {
          margin-left: auto;
          justify-content: flex-end;

          .user-img {
            order: 2;
            margin: 0 0 0 20px;
          }

          .userMessage {
            text-align: right;
          }
        }
      }
    }
  }

  .TypingBox {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    > div {
      &:nth-child(2) {
        width: calc(100% - 84px);

        .MuiFormControl-root {
          width: 100%;
          padding-right: 20px;

          .MuiInputBase-input {
            text-align: left;
            padding-left: 67px;
          }
        }
      }

      &:last-child {
        button {
          width: 84px;
          min-width: 84px;
        }
      }
    }

    .AttachmentPin {
      position: absolute;
      width: auto !important;
      z-index: 2;
      display: flex;
      margin-left: 27px;

      .fileupload-custom {
        .dropzone {
          background: transparent;
          height: auto;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.download-doc {
  border-bottom: 0;

  span {
    margin-left: 5px !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
    display: inline-block;
    border-bottom: 1px solid #f76155;
    color: #f76155 !important;
  }
}

.ChatUserAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0px 0;
}

.ChatUserProfile {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px;
  text-align: center;

  .UserAvtar {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    border-radius: 100px;
    background: #bfced8;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.no-user-image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: auto;
      }
    }
  }

  h3 {
    color: #000485;
    font-weight: 500;
    margin: 0 0 15px 0;

    img {
      margin-left: 10px;
    }
  }

  .detailsinfo {
    margin: 15px 0 32px 0;

    h5 {
      color: #000485;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }

    p {
      color: #000485;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin: 2px 0 0 0;
    }
  }

  .Description {
    color: #000485;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    max-width: 228px;
    margin: 0 auto 27px;
  }

  .total-participants {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px 0;

    h4 {
      color: #000485;
    }

    .tag {
      margin-left: 10px;
      min-width: 70px;
    }
  }
}

.tag {
  border-radius: 18.5px;
  background-color: #f1f4f7;
  display: inline-block;
  padding: 7px 7px 2px 7px;
  color: #000485;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  min-width: 84px;
  margin: 0;
  min-height: 28px;
  text-transform: capitalize;
}

ul.SocialUl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 0 15px;
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;

    .socialImg {
      height: 39px;
      width: 39px;
      border-radius: 29.41px;
      box-shadow: 0 1px 11px 2px rgba(209, 221, 229, 0.4);
      margin: 0 auto 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}

.topic-creator {
  text-align: left;
  max-width: 200px;
  margin: 34px auto 0;

  .topic-creator-item {
    .item {
      display: flex;
      align-items: center;

      .img-wrap {
        height: 40px;
        width: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin: 0 10px 0 0;

        img {
          width: 100%;
        }
      }

      h6 {
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        color: #000485;

        span {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin-left: 8px;
        }
      }

      + .item {
        margin-top: 15px;
      }
    }
  }

  .loadmore {
    margin: 20px 0 0 0;
  }
}

.PostContentWrap {
  .PostContentItem {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    padding: 40px 30px 30px;

    .PostHead {
      display: flex;
      align-items: center;
      margin: 0 0 14px 0;

      .profileImg {
        height: 50px;
        width: 50px;
        min-width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 0 20px 0 0;
        border-radius: 100px;

        img {
          width: 100%;
        }
      }

      .ProfileDiv {
        position: relative;
        top: 2px;

        h4 {
          color: #000485;
          margin: 0 0 2px 0;
        }

        p {
          color: #566d82;
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
        }
      }
    }

    .PostBody {
      p {
        margin: 0 0 22px 0;
      }

      .imgWrap {
        margin: 0 0 15px 0;

        img {
          width: 100%;
        }
      }

      h3 {
        color: #000485;
      }
    }

    .postComment {
      position: relative;
      padding-top: 24px;
      margin: 30px 0px 0 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1.5px;
        background: #d1dde5;
        top: 0;
      }

      .ImgWrap {
        height: 50px;
        width: 50px;
        background-color: #bfced8;
        min-width: 50px;
        border-radius: 100px;
        margin: 0 15px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .TypingBox {
        display: flex;
        align-items: center;

        > div {
          &:nth-child(2) {
            width: 100%;
            padding-right: 15px;

            .MuiFormControl-root {
              width: 100%;

              .MuiInputBase-input {
                height: 40px;
                min-height: auto;
                text-align: left;
                padding: 13px 20px 10px;
              }
            }
          }

          &:nth-child(3) {
            button {
              min-width: 84px;
            }
          }
        }
      }
    }

    .post-comments-wrapper {
      .postComment {
        max-width: 474px;
        margin: 30px auto 0 !important;
        border: 0 !important;
        padding: 0 !important;

        &:before {
          display: none;
        }
      }
    }
  }
}

.FilterMainWrap {
  padding: 0 48px;
}

.clearBtn {
  color: #0030b5;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #0030b5;
  cursor: pointer;
}

.FilterCheckList {
  h6 {
    color: #000485;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-family: "brandon_grotesquebold";
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: -15px 0 0 -11px;

      > div {
        margin: 0 15px 0 0;
      }

      + li {
        margin: -15px 0 0 -11px;
      }
    }

    &.admin-filters {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: 25%;
        margin: 0 0 15px 0;

        + li {
          margin-top: 0;
        }
      }
    }
  }
}

.year-checkbox-ul {
  display: flex;
  flex-wrap: wrap;

  li {
    width: 16.66%;
    margin: 0 0 10px 0 !important;
  }
}

.ShowDiv {
  margin: 15px 0 0 0;

  span {
    color: #0030b5;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    border-bottom: 1px solid #0030b5;
    cursor: pointer;
  }
}

.FilterSearchBox {
  margin: 0 20px 0 6px;

  .MuiInputBase-formControl {
    .MuiInputBase-input {
      padding: 0;
      height: 28px !important;
      min-height: auto;
      border-radius: 0 !important;
      border-left: 0;
      border-top: 0;
      border-right: 0;
      background: transparent;
      text-align: left;
      min-width: 250px;
      border-color: #607790;
    }
  }
}

.CreatePostWrap {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px 30px 30px;
  margin: 0 0 20px 0;

  label {
    margin: 0;
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0 0 8px 0;
  }

  .TypingBox {
    display: flex;
    align-items: center;
    position: relative;

    .AttachmentPin {
      position: absolute;
      width: auto !important;
      left: 27px;
      z-index: 2;
      top: 50%;
      display: flex;
      transform: translateY(-50%);
      margin-top: 0px;

      .fileupload-custom {
        .dropzone {
          background: transparent;
          height: auto;
          margin: 0;
          cursor: pointer;
          min-height: auto;
        }
      }
    }

    > div {
      &:nth-child(2) {
        width: 100%;
        padding-right: 20px;

        .MuiTextField-root {
          width: 100%;

          .MuiInputBase-input {
            text-align: left;
            padding-left: 67px;
          }
        }
      }

      button {
        min-width: 84px;
      }
    }
  }
}

.PostheadingWrap {
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;

  h3 {
    color: #000485;
    margin: 0 10px 0 0;
    padding-top: 5px;
  }

  button {
    border-radius: 18.5px !important;
    background-color: #f76155 !important;
    border-color: #f76155 !important;
    height: auto;
    padding: 2px 0 0 0 !important;
    min-height: 28px !important;
    font-size: 15px !important;
    line-height: 15px !important;
    font-family: "TTCommons-Regular" !important;
    min-width: 112px !important;
  }

  .RequestWrap {
    position: relative;

    .RequestWrapShowing {
      position: absolute;
      width: 100%;
      z-index: 1;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      min-width: 569px;
      top: 50px;
      padding: 25px;
      z-index: 11;

      &:before {
        content: "";
        position: absolute;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 15px solid white;
        top: -15px;
        left: 32px;
      }

      .RequestItem {
        display: flex;
        align-items: center;

        .ImgWrap {
          width: 40px;
          height: 40px;
          background: #bfced8;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin: 0 15px 0 0;
          min-width: 40px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .RequestContent {
          width: 100%;
          max-width: 205px;

          h4 {
            color: #000485;
            margin: 0 0 2px 0;
          }

          p {
            margin: 0;
          }
        }

        .RequestAction {
          margin-left: auto;

          span {
            display: inline-block;
            cursor: pointer;

            + span {
              margin-left: 20px;
            }
          }
        }

        + .RequestItem {
          border-top: 1px solid #e5eaf7;
          margin-top: 24px;
          padding-top: 24px;
        }
      }
    }
  }
}

.EmptyMessage {
  margin: 116px 0 0 0;
  color: #000485;

  h2 {
    margin: 0 0 17px 0;
  }
}

.PopupGraybg {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(128, 148, 171, 0.2);
  z-index: 10;
  overflow: hidden;
}

.webinar-registerd {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  max-width: 670px;
  margin: 0 auto;

  .box-wrap {
    display: flex;
    align-items: center;

    .left {
      min-width: 162px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 20px;
      padding-left: 20px;
      max-width: 162px;

      img {
        width: 100%;
      }
    }

    .right {
      padding: 20px 30px 20px 0;
      position: relative;
      width: calc(100% - 144px);

      h4 {
        color: #000485;
        max-width: 330px;
        position: relative;
        margin: 0 0 2px 0px;
        padding-right: 25px;

        img {
          margin: -2px 0 0 6px;
          // position: absolute;
          // right: 0;
          // top: 0;
        }

        img.star {
          max-width: 18px;
          position: initial;
          margin: -1x 0 0 6px;
        }

        + h4 {
          font-family: "TTCommons-Regular";
          margin: 0 0 7px 0;
        }
      }

      h5 {
        color: #607790;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        max-width: 330px;

        img {
          margin: -2px 10px 0 0;
        }

        + h5 {
          margin: 6px 0 6px 0;
        }
      }

      .rounded-btn {
        position: absolute;
        top: 34px;
        right: 30px;
      }
    }
  }

  &.webinar-resource {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      .left {
        border-radius: 10px 0 0 10px;
        background-color: rgba(241, 244, 247, 0.5);
        min-width: 174px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 20px 16px;

        img {
          max-width: 75px;
        }
      }

      .right {
        padding: 20px 30px 20px 20px;

        h4 {
          display: flex;
          align-items: flex-start;
        }

        .rounded-btn {
          top: 50%;
          transform: translateY(-50%);
          min-width: 85px !important;
        }
      }
    }
  }

  &.webinar-student-services {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      min-height: 111px;

      .right {
        .rounded-btn {
          top: 50%;
          transform: translateY(-50%);
          min-width: 115px !important;
        }
      }
    }
  }

  &.webinar-job-intern {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      .left {
        width: 50px;
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        padding: 0;
        margin: 0 20px;
        border-radius: 100px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .right {
        width: calc(100% - 90px);

        .rounded-btn {
          top: 50%;
          transform: translateY(-50%);
          min-width: 85px !important;
        }
      }
    }
  }
}

.link-wrap {
  .link {
    + .link {
      margin-left: 30px;
    }
  }
}

.preview-div {
  .preview-ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: 5px 5px 5px 5px;
      border-radius: 18.5px;
      background-color: #f1f4f7;
      padding: 6px 15px 3px;
      color: #000485;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      min-width: 63px;
      min-height: 28px;
    }
  }
}

.post-card {
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 2px rgba(209, 221, 229, 0.4);
  max-width: 280px;
  margin: 0 auto;

  .box-wrap {
    padding: 18px;

    .left {
      margin: 0 0 15px 0;

      img {
        width: 100%;
      }
    }

    .right {
      h4 {
        color: #000485;
        font-family: "TTCommons-Medium";
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23.1px;
        margin: 0 0 7px 0;
      }

      h5 {
        color: #566d82;
        margin: 0 0 7px 0;
      }

      p {
        margin: 0;
        font-size: 11.2px;
        color: #566d82;
        line-height: 16.8px;

        span {
          color: #000485;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16.8px;
          margin-left: 4px;
        }
      }
    }
  }

  &.main-post-card {
    max-width: 469px;

    .post-header {
      display: flex;
      align-items: center;
      margin: 0 0 13px 0;

      .img-wrap {
        width: 35px;
        height: 35px;
        border-radius: 100px;
        overflow: hidden;
        margin: 0 10px 0 0;

        img {
          width: 100%;
        }
      }

      .img-content {
        h4 {
          color: #000485;
          font-size: 12.6px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13.3px;
          margin: 0 0 2px 0;
        }

        p {
          color: #607790;
          line-height: 10.5px;
          font-size: 10.5px;
          margin: 0;
        }
      }

      .right {
        h5 {
          margin: 0 !important;
        }
      }

      .MuiCardHeader-action {
        margin-left: auto;
      }
    }
  }
}

.circle-card {
  .box-wrap {
    border-radius: 45px;
    background-color: #f1f4f7;
    padding: 25px 20px 16px 20px;
    text-align: center;

    h4 {
      color: #000485;
      margin: 0 0 6px 0;
    }

    h6 {
      color: #000485;
      line-height: 18px;
    }

    + .box-wrap {
      margin: 15px 0 0 0;
    }
  }

  &.max-width-card {
    max-width: 538px;
    margin: 0 auto;
  }

  .subtask-card {
    margin: 10px 0 0 0;

    .box-wrap {
      padding: 10px 20px 10px 20px;

      h4 {
        margin: 0;
      }

      + .box-wrap {
        margin: 10px 0 0 0;
      }
    }
  }
}

.calender-wrapper {
  margin: 50px 0 0 0;
  background: #f1f4f7;

  .fc-header-toolbar {
    display: none !important;
  }

  table {
    border: 0 !important;

    thead {
      th {
        border: 2px solid #fff;
        height: 64px;
        vertical-align: middle;
        color: #000485;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        border-bottom: 0;

        a {
          color: #000485;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-decoration: none;
          padding: 0 !important;
        }
      }
    }

    tbody {
      td {
        table {
          td {
            border: 2px solid #fff;
            padding: 12px;

            .fc-daygrid-day-frame {
              .fc-daygrid-day-top {
                opacity: 1;

                a {
                  font-family: "TTCommons-Medium";
                  font-size: 22px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 24px;
                  text-align: right;
                  padding: 0 !important;
                  color: #000485;
                  text-decoration: none;
                }
              }

              .fc-daygrid-day-events {
                .fc-daygrid-event {
                  margin: 0 !important;
                  background: transparent;
                  border: 0;

                  .fc-event-title {
                    color: #607790;
                    font-family: "TTCommons-Medium";
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                  }
                }
              }
            }

            &.fc-day-other {
              .fc-daygrid-day-frame {
                .fc-daygrid-day-top {
                  a {
                    color: #607790;
                  }
                }
              }
            }

            &.fc-day-today {
              background: #f1f4f7 !important;

              .fc-daygrid-day-frame {
                .fc-daygrid-day-top {
                  a {
                    height: 44px;
                    width: 44px;
                    background-color: #000485;
                    color: #fff !important;
                    display: flex;
                    border-radius: 100px;
                    justify-content: center;
                    align-items: center;
                    padding-top: 4px !important;
                  }
                }
              }
            }

            &.fc-day-future {
              .fc-daygrid-day-events {
                .fc-daygrid-event {
                  .fc-event-title {
                    color: #000485;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.appointment-card {
  border-radius: 45px;
  background-color: #f1f4f7;
  max-width: 448px;
  margin: 0 auto;
  padding: 22px 50px 28px;

  h3 {
    color: #000485;
    margin: 0 0 8px 0;
  }

  h4 {
    color: #000485;
    margin: 0 0 4px 0;
  }

  .text-gray {
    color: #607790;
  }

  h5 {
    color: #000485;

    .link {
      color: #f76155 !important;
      border-bottom-color: #f76155;
      margin-left: 4px;
    }
  }
}

.event-popop {
  .MuiPaper-root {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    width: 100%;
    max-width: 404px; // top: 200px !important;

    .nevent-box {
      padding: 30px;

      .close-btn {
        width: 10px;
        margin-left: auto;
        display: flex;
        height: 10px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .box-wrap {
        h3 {
          color: #000485;
          margin: 0 0 9px 0;
        }

        h4 {
          color: #000485;
          margin: 0 0 9px 0;

          + img {
            position: absolute;
            right: 30px;
            top: 75px;
          }
        }

        .text-gray {
          color: #607790;
          margin: 0 0 15px 0 !important;
        }

        h5 {
          color: #000485;
          margin: 0 0 6px 0;

          + h5 {
            margin-bottom: 0;
          }

          .link {
            color: #f76155 !important;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
            border-bottom-color: #f76155;
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.Toastify {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .Toastify__toast-container {
    left: auto;
    transform: none;
    bottom: 47px;
    width: 100%;
    max-width: 640px;
    padding: 0;

    .Toastify__toast {
      margin: 0;
      border-radius: 7px;
      background-color: #011fa2;
      min-height: auto;
      padding: 21px 30px 15px;
      align-items: center;
      transform: none !important;

      .Toastify__toast-body {
        margin: 0;
        padding: 0;

        .d-flex {
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
        }
      }

      .Toastify__close-button {
        opacity: 1;
        line-height: normal;
        position: relative;
        top: -2px;
        color: #fff;
      }

      .Toastify__progress-bar {
        visibility: hidden;
      }
    }
  }
}

.customscroll-div {
  > div {
    div {
      &:nth-child(2) {
        display: none !important;
      }

      &:nth-child(3) {
        width: 8px !important;
        border: 2px solid #d1dde5;
        border-radius: 4px !important;
        right: 20px !important;

        div {
          background-color: #d1dde5 !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}

.dropdown-wrapper {
  position: relative;

  + .dropdown-wrapper {
    margin: 15px 0 0 0;
  }

  .custom-dropdownul {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: none;

    &.no-option {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;

      li {
        justify-content: center;
      }
    }
  }

  &.open {
    .custom-dropdownul {
      display: block;
    }

    .custom-dropdowninput {
      span {
        transform: rotate(180deg);
      }
    }
  }

  &.multili {
    .custom-dropdownul {
      ul {
        li {
          padding: 0;
          background: transparent;
          color: #607790;
          word-break: break-word;

          + li {
            margin-top: 15px;
          }

          &.active {
            color: #000485;
          }
        }
      }
    }
  }
}

.custom-dropdowninput {
  .MuiFormControl-root {
    width: 100%;
    margin: 0 0 0px 0;
  }

  > span {
    position: absolute;
    right: 15px;
    top: 16px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    //background-color: #fafbfd;
  }
}

.custom-dropdownul {
  border: 2px solid #d1dde5;
  border-radius: 6px;
  background-color: #ffffff;
  //background-color: #fafbfd;
  padding: 30px;
  margin: 0 0 20px 0;

  > ul {
    border-bottom: 1px solid #e8eef2;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }

  > div {
    > div {
      &:last-child {
        right: 0 !important;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      align-items: center;
      margin-right: 8px;

      > div {
        margin: 0 15px 0 0;
      }

      + li {
        margin: 15px 0px 0 0;
      }

      &.active {
        color: #0030b5;
      }
    }
  }
}

.dropdown-array-option {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .MuiChip-root {
    border-radius: 18.5px;
    background-color: #f1f4f7;
    border: 0;
    height: 28px;
    padding: 4px 8px 6px 10px;
    margin: 5px 5px 5px;
    color: #0030b5;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    max-width: 200px;

    .MuiChip-label {
      padding: 0;
      margin: 0 10px 0 0px;
      width: 90%;
    }

    svg {
      margin: 2px 0 0 0;
      width: 10px;
      height: 10px;
    }
  }
}

.MuiAlert-standardInfo {
  padding: 12px 40px 12px 30px !important;
  background-color: #000485 !important;
  color: #fff !important;
  position: fixed;
  left: 70px;
  top: 64px;
  z-index: 1050;
  right: 0;
  box-sizing: border-box !important;
  max-width: 100%;
  border-radius: 0 !important;

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: "TTCommons-Regular";

    .MuiAlertTitle-root {
      margin: 0 20px 0 0;
      font-size: 22px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      line-height: 24px;
      padding: 4px 0 0 0;
    }

    button {
      min-width: 117px;
      margin-left: 52px;
    }
  }

  .MuiAlert-action {
    padding-left: 0;
    margin-left: auto;
  }
}

.qa-table {
  tr {
    td {
      &:nth-child(2) {
        min-width: 450px;
        width: 450px;
      }

      &:last-child {
        width: 100px;
      }
    }
  }
}

.post-comments-wrapper {
  margin: 0 auto;
  border-top: 1.5px solid #d1dde5;
  margin-top: 25px;
  padding-top: 25px;

  .link-div {
    max-width: 474px;
    margin: 0 auto 30px;

    .showcomments {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      border-bottom: 1px solid #607790;
      text-decoration: none;
    }
  }

  .comment-item {
    max-width: 474px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .profileImg {
      width: 33px;
      height: 33px;
      border-radius: 100px;
      overflow: hidden;
      margin: -18px 15px 0 0;

      img {
        width: 100%;
      }
    }

    .comment-content {
      width: calc(100% - 48px);

      h4 {
        color: #000485;

        span {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          margin-left: 8px;
        }
      }

      p {
        margin: 0;
        color: #000485;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
    }

    + .comment-item {
      margin-top: 25px;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(128, 148, 171, 0.2) !important;
}

.MuiCardHeader-action {
  position: relative;

  button {
    height: 28px;
    width: 28px;
    border: 2px solid #d1dde5;
    border-radius: 31.5px;
    background-color: #ffffff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #607790;
    }
  }

  ul.sub-menu {
    margin: 8px 0px 0px;
    list-style: none;
    position: absolute;
    top: 100%;
    right: 2%;
    border-radius: 10px 1px 10px 10px;
    border: 2px solid #d1dde5;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
    width: 200px;
    padding: 0px 20px;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1;
    display: none;

    li {
      a {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        display: block;
        padding: 18px 0px 12.5px;
        color: #000485;
        font-family: TTCommons-Medium;
        line-height: 21px;
        justify-content: center;

        b {
          font-weight: 500;
        }
      }

      + li {
        border-top: 1px solid #d1dde5;
      }
    }
  }

  &.open {
    ul.sub-menu {
      display: block;
    }
  }
}

.international-students-section {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px 20px;
  max-width: 325px;

  .box-wrap {
    flex-wrap: wrap;

    h4 {
      margin: 0 0 19px 0;
      color: #000485;
      font-weight: 500;
    }

    .d-flex {
      justify-content: space-between;
      width: 100%;

      h5 {
        color: #607790;
      }

      a {
        color: #f76155;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        text-decoration: none;
      }
    }
  }
}

.new-chat-dialogue {
  .MuiDialog-paper {
    max-width: 900px !important;
    width: 100%;
    padding: 50px !important;

    #confirmation-dialog-title {
      color: #000485;
      width: 100%;
      display: flex;
      font-size: 30px;
      text-align: center;
      align-items: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      line-height: 31px;
      justify-content: center;
      margin: 0 0 30px 0 !important;
    }

    .MuiDialogContent-root {
      margin: 0;

      .search-network {
        max-width: 320px;
        margin: 0 auto 25px;

        label {
          margin: 0 0 8px !important;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
        }

        .MuiFormControl-root {
          margin: 0;
        }
      }
    }

    .chat-group {
      padding: 0 15px;
      padding: 0 15px;
      max-width: 650px;
      margin: 0 auto;

      .chat-item {
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
        padding: 23px 40px 23px 25px;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        min-height: 103px;

        .img {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 15px 0 0px;
          overflow: hidden;

          img {
            width: 100%;
          }

          &.no-user-image {
            background: #f1f1f1;

            img {
              width: auto;
            }
          }
        }

        .usreDescription {
          width: calc(100% - 215px);
          text-align: left;
        }

        .ChatStatus {
          margin-left: auto;

          button {
            min-width: auto;
          }
        }

        + .chat-item {
          margin-top: 20px;
        }
      }
    }
  }
}

// style-guid-css-over
.main-content {
  min-height: 100vh;
  padding: 100px 30px 20px 100px;

  .nav-sidebar {
    width: 70px;
    background-color: #000c3d;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;

    .nav-header {
      display: flex;
      align-items: center;
      min-height: 64px;
      margin-bottom: 16px;

      a {
        display: block;
        width: 35px;
        overflow: hidden;
        margin: 0 auto;

        img {
          width: 160px;
          vertical-align: middle;
        }
      }
    }

    .navmenu {
      display: flex;
      height: calc(100% - 105px);
      position: relative;
      padding-bottom: 0.8rem;

      .left-menu {
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        position: relative;

        > div {
          > div {
            &:last-child {
              right: 0 !important;
              width: 2px !important;

              div {
                background: #0030b5 !important;
              }
            }
          }
        }

        .left-ulWrap {
          height: calc(100% - 95px); // overflow-y: auto;

          > div {
            height: 100% !important;
          }
        }

        ul.left-ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            a {
              width: 40px;
              display: flex;
              height: 40px;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              line-height: 1.5;
              padding: 0;
              border-radius: 3px;
              text-decoration: none;

              &:hover {
                background-color: #0030b5;
              }

              &.active {
                background-color: #0030b5;
              }

              svg {
                width: 19px;
                fill: #fff;
                height: 19px;
              }
            }

            .right-menu {
              display: none;
            }
          }

          > li + li {
            margin-top: 15px;
          }

          &.bottom {
            position: absolute;
            bottom: 0;
            left: 15px;
          }
        }
      }

      .right-menu {
        width: calc(100% - 40px);
        border-left: 1.5px solid #607790;
        padding-left: 10.5px;
        padding-right: 10.5px;
        display: none;

        .divider {
          margin-top: 12px;
          border-top: 1.5px solid rgba(96, 119, 144, 0.51);
          padding-top: 18px;
        }

        h3 {
          color: #ffffff;
          font-family: "brandon_grotesquebold";
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          text-align: left;
          text-transform: uppercase;
          margin: 0 0 12px 11px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          text-align: left;

          li {
            a {
              padding: 12px 11px 9px 11px;
              display: block;
              width: 100%;
              color: #ffffff;
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              border-radius: 3px;
              text-decoration: none;

              &:hover,
              &.active {
                background-color: #0030b5;
              }

              img {
                width: 20px;
                margin-left: auto;
                vertical-align: middle;
                margin-right: 8px;
                margin-top: -1px;
              }
            }

            + li {
              margin: 5px 0 0 0;
            }

            &.submenu {
              ul {
                padding: 0 0 0 20px;
                display: none;
              }

              &.open {
                ul {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &.Sidebar-open {
    padding: 114px 30px 20px 290px;

    .nav-sidebar {
      width: 260px;

      .nav-header {
        a {
          width: auto;
        }
      }

      .left-menu {
        ul.left-ul {
          li {
            &.active {
              .right-menu {
                display: block;
                position: absolute;
                top: 0;
                left: 70px;
                border-left: 1.5px solid rgba(96, 119, 144, 0.51);
                height: 100%;
                padding: 0 11px;
                width: 190px;

                ul {
                  li {
                    border-radius: 4px;

                    a {
                      width: auto;
                      height: auto;
                      padding: 7px 11px 5px;
                      line-height: 1.5;
                      justify-content: flex-start;
                      border-radius: 4px;

                      &:hover {
                        background-color: #0030b5;
                      }
                    }

                    &.submenu {
                      &.open {
                        a {
                          img {
                            transform: rotate(-180deg);
                            margin-left: auto;
                          }
                        }
                      }
                    }

                    &.SubMenu {
                      ul {
                        padding-left: 1.5rem;
                        display: none;
                      }

                      &.openMenu {
                        > a {
                          background-image: $angle-down-arrow-img;
                          background-size: 12px;
                          background-repeat: no-repeat;
                          background-position: calc(100% - 15px) 12px;
                        }

                        ul {
                          display: block;
                        }
                      }

                      &.closeMenu {
                        > a {
                          background-image: $angle-down-arrow-img;
                          background-size: 12px;
                          background-repeat: no-repeat;
                          background-position: calc(100% - 15px) 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .right-menu {
        display: block;
      }
    }

    header.header {
      width: calc(100% - 260px);
      left: 260px;
    }

    .MuiAlert-standardInfo {
      left: 260px;
    }
  }

  header.header {
    background: #fff;
    border-bottom: 2px solid #d1dde5;
    padding: 10px 24px;
    position: fixed;
    top: 0;
    width: calc(100% - 70px);
    left: 70px;
    z-index: 10;
    height: 64px;
    align-items: center;
    display: flex;
    z-index: 1051;

    .HeaderLeft {
      display: flex;
      align-items: center;

      // width: 35%;
      > div {
        width: 100%;
      }

      .university-logo {
        margin: 0 25px 0 0;

        img {
          max-height: 44px;
          vertical-align: middle;
        }
      }

      a {
        border-radius: 18.5px;
        background-color: #f1f4f7;
        padding: 10px 13px 6px;
        color: #566d82;
        font-family: "TTCommons-Regular";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        text-decoration: none;
      }

      .dropdown-wrapper {
        .custom-dropdowninput {
          input {
            padding: 0;
            border-radius: 100px !important;
            overflow: hidden;
            padding: 16.15px 20px 12.15px;
            padding-right: 50px !important;
            min-height: 44px;
            text-align: left;
          }

          > span {
            top: 7px;
          }
        }
      }
    }

    .HeaderRight {
      margin: 0 0 0 auto;
      display: flex;

      a {
        margin: 0 0 0 16px;
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: #e5eaf7;

        &.Question-Icon {
          svg {
            fill: #607790;
          }

          &.active,
          &:hover {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }

        &.Bell-Icon {
          svg {
            stroke: #607790;
          }

          &.active,
          &:hover {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }

        &.User-Icon {
          svg {
            stroke: #607790;
          }

          &.active,
          &:hover {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }

        &.active,
        &:hover {
          background: #0030b5;
        }
      }
    }
  }

  &.alert-open {
    padding-top: 160px;
  }
}

.page-header {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  min-height: 40px;

  h3 {
    color: #000485;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    font-family: "TTCommons-Medium";
    margin: 0;
  }

  .left {
    display: flex;
    align-items: center;

    .total-number {
      font-size: 22px;
      font-weight: normal;
      color: #000485;
      padding-left: 10px;
      padding-top: 4px;
      font-family: "TTCommons-Medium";
      line-height: 21px;
    }
  }

  .tabs-div {
    display: flex;
    align-items: center;
    margin-left: 30px;

    > div {
      // text-decoration: none !important;
      color: #000485;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      font-family: "TTCommons-Medium";
      margin: 0 30px 0 0 !important;
      padding: 6px 0 0 0 !important;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid #000485;
      }
    }
  }

  .import-options {
    button {
      padding: 0;
      color: #607790;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      font-family: "TTCommons-Regular";
      background: transparent;

      &.Mui-disabled {
        opacity: 0.4;
      }

      img {
        width: 40px;
        margin: -4px 0px 0 0;
        vertical-align: middle;
      }

      + button {
        margin-left: 20px;
      }

      &:hover {
        color: #0030b5;
      }
    }
  }
}

.filter-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  justify-content: space-between;

  &.disabled {
    .filter-left,
    .filter-right {
      a {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  .filter-left {
    display: flex;
    align-items: center;

    a {
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      + a {
        margin-left: 8px;
      }

      &.selected {
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      }
    }

    .FilterSorting {
      margin: 0 0px 0 10px;

      .FilterList {
        color: #000485;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin-right: 10px;
      }
    }
  }

  .filter-right {
    display: flex;
    align-items: center;

    > div {
      + div {
        margin-left: 20px;
      }
    }

    &.export-action {
      .export-button {
        font-size: 15px;

        .MuiIconButton-label {
          font-size: 15px;
        }
      }
    }
  }
}

.custom-table {
  margin: -10px -15px 0;
  .custom-table-container {
    overflow-x: unset;
  }
  > .MuiPaper-root {
    border: 0;
    box-shadow: none !important;
    background: transparent;
    padding: 0 15px;

    > table {
      table-layout: fixed;
      border-spacing: 0 10px !important;
      border-collapse: initial;
    }

    table {
      table-layout: fixed;
    }

    .thead-row {
      td {
        padding: 0;
        border: 0;

        table {
          tr {
            td {
              padding: 22px 10px 15px;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #8094ab;
              text-transform: uppercase;
              font-family: "brandon_grotesquebold";
              border: 0;

              h5 {
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 1.5px;
                line-height: 16px;
                color: #8094ab;
                text-transform: uppercase;
                font-family: "brandon_grotesquebold";
                margin: 0;
                display: flex;
                min-width: 100px;
                align-items: center;

                svg {
                  width: 12px;
                  vertical-align: middle;
                  margin: 0px 0 0 6px;
                  height: 12px;
                  fill: #8094ab;
                  width: 24px;
                  cursor: pointer;
                  margin-left: 0;
                }

                &:hover {
                  color: #000485;

                  svg {
                    fill: #000485;
                  }
                }

                &.active {
                  color: #000485;

                  svg {
                    fill: #000485;
                  }
                }
              }

              // &:first-child {
              //   width: 70px;
              //   text-align: center;
              //   padding-left: 0;
              //   padding-right: 0;
              //   height: 64px;
              // }
              &:first-child {
                padding-left: 60px;
              }
            }
          }
        }
      }
    }

    .tbody-row {
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

      .main-text {
        color: #000485;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }

      td {
        padding: 0;
        border: 0;

        table {
          tr {
            td {
              padding: 12px 10px 12px;
              color: #000485;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              font-family: "TTCommons-Regular";
              border: 0;
              min-height: 74px;

              .MuiCheckbox-root {
                padding: 0;

                svg {
                  width: 24px;
                  height: 24px;
                  fill: #8094ab;
                  margin: 0;
                }

                &.Mui-checked {
                  svg {
                    fill: #000485;
                  }
                }
              }

              svg {
                width: 12px;
                vertical-align: middle;
                margin: -4px 0 0 8px;
                height: 12px;
              }

              // &:first-child {
              //   width: 70px;
              //   text-align: center;
              //   padding-left: 0;
              //   padding-right: 0;
              // }
              &:first-child {
                padding-left: 30px;
              }

              &:last-child {
                padding-right: 15px;
              }
            }

            &:hover {
              .table-actions {
                opacity: 1;
                transition: all 0.3s;
              }
            }
          }
        }

        .collapse-table {
          width: 100%;
          margin: 0 auto;
          text-align: left;

          // display: none;
          tr {
            td {
              padding: 18px 10px 25px;
              border: 0;
              border-top: 1.5px solid #e8eef2;
              text-align: left !important;
              width: auto !important;

              &:first-child {
                width: 70px !important;
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                border-top: 0;
              }

              &:last-child {
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                border-top: 0;
                width: 42px !important;
              }

              h6 {
                color: #8094ab;
                font-family: "brandon_grotesquebold";
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 1.5px;
                line-height: 16px;
                text-transform: uppercase;
                margin: 0 0 3px 0;
              }

              h5 {
                color: #000485;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                font-family: "TTCommons-Regular";
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &.country-insight {
    .thead-row {
      td {
        text-align: center;
        width: 96px;

        h5 {
          justify-content: center;
          min-width: auto !important;
        }

        &:nth-child(2) {
          text-align: left;
          width: 96px;

          h5 {
            justify-content: left;
          }
        }
      }
    }

    .tbody-row {
      td {
        text-align: center;
        width: 96px;

        &:nth-child(2) {
          text-align: left;
          width: 96px;
        }
      }
    }
  }
}

// .custom-table > .MuiPaper-root table:hover + .collapse-table {
//   display: table;
// }
.tbody-row {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

  > td {
    border-radius: 4px;
  }

  td {
    padding: 0;
    border: 0;

    table {
      tr {
        td {
          padding: 18px 10px 16px;
          color: #000485;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Regular";
          border: 0;
          white-space: normal;
          word-break: break-word;

          .MuiCheckbox-root {
            padding: 0;

            svg {
              width: 24px;
              height: 24px;
              fill: #8094ab;
              margin: 0;
            }

            &.Mui-checked {
              svg {
                fill: #000485;
              }
            }
          }

          svg {
            width: 12px;
            vertical-align: middle;
            margin: -4px 0 0 8px;
            height: 12px;
          }

          // &:first-child {
          //   width: 70px;
          //   text-align: center;
          //   padding-left: 0;
          //   padding-right: 0;
          //   height: 74px;
          // }
        }
      }
    }

    .collapse-table {
      width: 100%;
      margin: 0 auto;
      text-align: left;

      tr {
        td {
          padding: 18px 25px 18px;
          border: 0;
          border-top: 1.5px solid #e8eef2;
          text-align: left !important;
          width: auto !important;

          &:first-child {
            width: 70px !important;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
          }

          h6 {
            color: #8094ab;
            font-family: "brandon_grotesquebold";
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 1.5px;
            line-height: 16px;
            text-transform: uppercase;
            margin: 0 0 3px 0;
          }

          h5 {
            color: #000485;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            font-family: "TTCommons-Regular";
            margin: 0;
          }
        }
      }
    }
  }
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;

  input[type="checkbox"] {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    left: 0;
    opacity: 0;

    &:checked {
      + span.checkmark {
        img:nth-child(2) {
          display: block;
        }
      }
    }
  }

  span.checkmark {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    display: block;

    img:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
  }
}

.drag-wrapper {
  + .drag-wrapper {
    margin: 15px 0 0 0;
  }
}

.drag-content-wrap {
  .bg-wrap {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

    .drag-main-row {
      height: 70px;

      .drag-item {
        display: flex;
        padding: 12px 30px 16px;

        .left {
          display: flex;
          align-items: center;
          width: 50%;

          .img-wrap {
            margin: 0 20px 0 0;
            max-width: 30px;
          }
        }

        .drag-icon {
          margin: 0 30px 0 0;
          cursor: pointer;
        }

        .card-icon {
          margin: 0 20px 0 0;
        }

        h3 {
          color: #000485;
          margin: 0 20px 0 0;
          // max-width: 380px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        a {
          color: #000485;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Regular";

          img {
            margin: 0 0 0 10px;
            height: auto;
            width: 12px;
          }
        }

        .actions-div {
          margin-left: auto;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: all 0.3s;

          a {
            cursor: pointer;
            min-width: 40px;

            &:hover {
              svg,
              svg * {
                fill: #0030b5;
              }
            }
          }
        }

        &.disabled {
          .drag-icon,
          .img-wrap {
            opacity: 0.8;
          }

          h6,
          h3,
          a {
            color: #8094ab !important;
          }

          .actions-div {
            opacity: 0.8;
          }
        }

        &:hover {
          .actions-div {
            opacity: 1;
            transition: all 0.3s;
          }
        }
      }
    }

    .drag-collapse-row {
      padding: 0 25px 0 25px;

      .display-block {
        display: block;
      }

      > div {
        .collapse-item {
          padding: 0 0 0 22px;

          + .collapse-item {
            margin: 18px 0 0 0;
            padding-top: 18px;
            border-top: 1.5px solid #e8eef2;
          }

          &:last-child {
            padding-bottom: 35px;
          }
        }
      }

      .collapse-item {
        display: flex;
        align-items: center;

        .drag-icon {
          margin: 0 30px 0 0;
        }

        .img-wrap {
          width: 100%;
          max-width: 95px;
          margin: 0 22px 0 0;

          img {
            width: 100%;
          }
        }

        .resources-img-wrap {
          width: 153.63px;
          height: 86.43px;
          margin: 0 15px 0 0;
          position: relative;
          cursor: pointer;

          img {
            width: 100% !important;
            height: 100%;
            object-fit: contain;
            object-position: center;

            &.play-icon {
              width: 60px !important;
              height: 60px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              object-fit: initial;
            }
          }

          &.normal-image {
            width: 153.63px !important;
            height: 86.43px !important;
          }

          &.video-image {
            display: flex;

            img:first-child {
              height: 100% !important;
              object-fit: cover !important;
            }
          }
        }

        h3 {
          color: #8094ab;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          font-family: "brandon_grotesquebold";
          margin: 0 0 9px 0;
        }

        h4 {
          color: #000485;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          margin: 0 0 10px 0;
        }

        p {
          color: #607790;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0;
          font-family: "TTCommons-Regular";

          + p {
            margin: 10px 0 0 0;
          }
        }

        .actions-div {
          padding: 0 4px 0 22px;
          display: flex;
          align-items: center;
          margin-left: auto;

          a {
            min-width: 40px;
          }
        }
      }
    }

    + .bg-wrap {
      margin-top: 10px;
    }
  }
}

.kits-list {
  .image-wrapper {
    width: 100%;
    min-width: 200px;
    min-height: 90px;
    max-width: 200px;
    max-height: 90px;
    margin: 0 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 120px;
    }
  }

  .video-wrapper {
    width: 100%;
    min-width: 200px;
    min-height: 115px;
    max-width: 200px;
    max-height: 115px;
    margin: 0 15px 0 15px;
    display: flex;
    align-items: center;

    video {
      max-width: 200px;
      max-height: 115px;
      width: 100%;
      height: 100%;
    }
  }
}

.homefeed {
  .homefeed-content {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

    .homefeed-item {
      display: flex;
      padding: 25px 30px 25px;
      align-items: center;

      .img-wrap {
        width: 100%;
        max-width: 145px;
        margin: 0 15px 0 0;
      }

      .text-content {
        width: 100%;
        max-width: 630px;

        h5 {
          color: #8094ab;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          font-family: "brandon_grotesquebold";
          text-transform: uppercase;
          margin: 0 0 7px 0;
        }

        h3 {
          margin: 0 0 9px 0;
          font-size: 18px;
          line-height: 19px;
          color: #000485;
        }

        p {
          margin: 0 0 8px 0;
          color: #000485;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-transform: capitalize;
        }

        h6 {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0;
        }

        .tagline-text {
          color: #8094ab;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          font-family: "brandon_grotesquebold";
          text-transform: uppercase;
          margin: 0 0 7px 0;
        }

        .title-text {
          color: #000485;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 0 7px 0;
        }

        .description-text {
          color: #000485;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 9px 0;
        }

        .time-text {
          color: #000485 !important;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 6px 0;
        }

        .by-author {
          font-family: "TTCommons-Regular";
          font-size: 15px !important;
          letter-spacing: 0 !important;
          line-height: 17px;
          margin: 0 !important;
          text-transform: initial;
          font-weight: normal;
        }

        .webinar-button {
          margin-left: 0 !important;
          margin-top: 10px !important;

          .rounded-btn {
            min-width: 90px !important;
            padding: 1px 15px 0 15px !important;
          }
        }
      }

      &:hover {
        .actions-div {
          opacity: 1;
          transition: all 0.3s;
        }
      }

      .actions-div {
        margin-left: auto;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.3s;

        span {
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;
          justify-content: center;
        }

        a {
          cursor: pointer;
          min-width: 40px;
          text-align: center;
        }
      }
    }
  }

  + .homefeed {
    margin: 15px 0 0 0;
  }
}

// home page CSS
.welcome-page {
  h2 {
    color: #000485;
    margin: 0 0 20px 0;
  }

  .lookingFor {
    margin: 0 0 30px 0;

    h3 {
      margin: 0 0 36px 0;
      color: #000485;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      color: #607790;
      font-size: 17px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Regular";

      li {
        cursor: pointer;

        img:first-child {
          margin: -1px 8px 0 0;
        }

        img:last-child {
          margin: -1px 0px 0 5px;
        }
      }

      &.topics {
        display: flex;
        flex-wrap: wrap;

        li {
          margin: 0 0 21px 0;
          width: 50%;
        }
      }

      &.missed-topics {
        color: #000485;

        li {
          margin: 0 0 18px 0;
        }
      }
    }
  }

  .platform-div {
    + .platform-div {
      margin: 30px 0 0 0;
    }

    h3 {
      color: #000485;
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 30px 0;
    }

    .bg-div {
      border-radius: 3px;
      background-color: #e5eaf7;
      text-align: center;
      padding: 30px 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 171px;

      h1 {
        width: 100%;
        color: #000485;
      }

      p {
        color: #000485;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 0;
      }
    }

    &.n-plateform-div {
      .row {
        .col-md-2 {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
  }
}

.page-title {
  color: #000485;
  margin: 0 0 30px 0;
}

.page-subTitle {
  color: #000485;
}

.profile-content {
  .page-title {
    margin-bottom: 50px;
  }

  .profile-form {
    max-width: 950px;
    margin: 0 auto;

    h3 {
      color: #000485;
      margin: 0 0 30px 0;
    }

    .row {
      margin: 0 -50px;

      .col-md-6 {
        padding: 0 50px;

        + .col-md-6 {
          border-left: 1.5px solid #d1dde5;
        }
      }
    }

    .form-group {
      margin-bottom: 0;

      > div {
        width: 100%;

        .MuiFormControl-root {
          width: 100%;
        }
      }

      .fileupload-custom {
        .dropzone {
          width: 111px;
          height: 111px;
          border-radius: 100px;
          margin: 0 auto 10px;
          min-height: 111px;
        }
      }

      + .form-group {
        margin: 15px 0 0 0;
      }
    }
  }
}

.profile-upload {
  text-align: center;
}

.change-password {
  margin: 15px 0 0 0;

  a {
    color: #0030b5;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    font-family: "TTCommons-Regular";
    border-bottom: 1px solid #0030b5;
    display: inline-block;
    text-decoration: none;
  }
}

//added by anand for drag and drop
.collapse-item {
  display: flex;
  align-items: center;

  .drag-icon {
    margin: 0 30px 0 0;
  }

  .img-wrap {
    width: 100%;
    max-width: 145px;
    margin: 0 22px 0 0;

    img {
      width: 100%;
    }
  }

  .resources-img-wrap {
    width: 153.63px;
    height: 86.43px;
    margin: 0 15px 0 0;
    position: relative;
    cursor: pointer;

    img {
      width: 100% !important;
      height: 100%;
      object-fit: contain;
      object-position: center;

      &.play-icon {
        width: 60px !important;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: initial;
      }
    }

    &.normal-image {
      width: 153.63px !important;
      height: 86.43px !important;
    }

    &.video-image {
      display: flex;

      img:first-child {
        height: 100% !important;
        object-fit: cover !important;
      }
    }
  }

  h3 {
    color: #8094ab;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 16px;
    font-family: "brandon_grotesquebold";
    margin: 0 0 9px 0;
  }

  h4 {
    color: #000485;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    margin: 0 0 10px 0;
  }

  p {
    color: #607790;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0;
    font-family: "TTCommons-Regular";

    + p {
      margin: 10px 0 0 0;
    }
  }

  .actions-div {
    padding: 0 4px 0 22px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.wizzard-content {
  position: relative;

  .back-div {
    color: #607790;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    position: absolute;
    left: 0;
    top: 5px;
    cursor: pointer;

    img {
      margin: -2px 8px 0 0;
    }
  }

  .step-title {
    color: #000485;
  }

  .wizzard-form {
    min-height: calc(100vh - 310px);

    .max-width {
      max-width: 535px;
      margin: 0 auto;
    }

    .form-group {
      > div {
        width: 100%;

        .MuiFormControl-root {
          width: 100%;
          z-index: 0;
        }
      }
    }

    .single-select-content {
      > div {
        max-width: 188px;
      }
    }

    &.step-two {
      .single-select-content {
        > div {
          max-width: 260px;
        }
      }
    }
  }

  .wizard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;
    border-top: 1px solid #e8eef2;
    margin: 55px 0 0 0;
    position: relative;

    &:before {
      content: "";
      height: 1px;
      background: #e8eef2;
      width: 50%;
      position: absolute;
      top: -1px;
      left: -50%;
      z-index: -1;
    }

    &:after {
      content: "";
      height: 1px;
      background: #e8eef2;
      width: 50%;
      position: absolute;
      top: -1px;
      //right: -50%;
      z-index: -1;
    }

    button {
      min-width: 162px;
      margin: 0 15px;
    }
  }
}

.loading-text {
  display: block;
  margin: 0 auto 15px;
  text-align: center;
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: normal;
  line-height: 18px;
}

.Sidebar-open {
  .loader {
    margin-left: 120px !important;
  }
}

@media (max-width: 1400px) {
  .MuiAlert-standardInfo {
    .MuiAlert-message {
      font-size: 15px;

      .MuiAlertTitle-root {
        line-height: 18px;
      }

      button {
        margin-left: 40px;
      }
    }
  }
}

.mgTopEvent {
  margin-top: 100px !important;
}

.naction-btn {
  padding: 0;
  border: 0;
}

.MuiPagination-ul {
  justify-content: flex-end;
  margin: 15px 0 15px 0 !important;

  li {
    margin: 0 5px;

    button {
      margin: 0;
      border-radius: 0;
      border: 2px solid rgba(96, 119, 144, 0.1);
      opacity: 1 !important;
      border-radius: 4px;
      color: #000485;
      font-family: "brandon_grotesquebold";

      &.Mui-selected,
      &:hover {
        background: #0030b5 !important;
        color: #fff !important;
        border-color: #0030b5 !important;
      }

      &.Mui-disabled {
        opacity: 0.4 !important;
      }
    }
  }

  .MuiPaginationItem-ellipsis {
    color: #0030b5;
    font-size: 24px;
    margin: -15px 0 0 0;
  }
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .pagination-dropdown {
    display: flex;
    align-items: center;

    span {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin-right: 10px;
    }

    .dropdown-wrapper {
      max-width: 120px;

      .MuiOutlinedInput-input {
        min-height: 40px;
        padding: 15px 30px 10px 15px;
        text-align: left;
        border-radius: 4px !important;
      }

      .custom-dropdowninput {
        span {
          right: 0px;
          top: 5px;
        }

        .MuiFormControl-root {
          margin: 0;
        }
      }

      .custom-dropdownul {
        padding: 15px 12px;
      }
    }
  }

  .MuiPagination-root {
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.profile-action {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0 15px;
  }
}

.table-actions {
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.3s;

  &.flex-start {
    justify-content: flex-start;
  }
}

.checklist-wrapper {
  .drag-content-wrap {
    .bg-wrap {
      .drag-main-row {
      }

      .drag-collapse-row {
        border-top: 2px solid #e8eef2;
        margin: 0 30px 0px 70px;
        padding: 0;
        padding-top: 25px;
        padding-bottom: 25px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          .main-cat {
            display: flex;
            align-items: center;

            span {
              color: #000485;
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
            }

            .actions {
              margin-left: auto;
            }
          }

          .sub-cat {
            margin-left: 40px;
            margin-top: 0;

            li {
              display: flex;
              align-items: center;

              span {
                color: #000485;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 19px;
              }

              .actions {
                margin-left: auto;
                margin-right: 80px;

                svg {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.country-insights-table-wrapper {
  .custom-table {
    .thead-row {
      td {
        h5 {
          justify-content: center;
          text-align: center;
        }
      }
    }

    .tbody-row {
      td {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
        }

        .collapse-table {
          .country-insights-links-wrapper {
            h6 {
              margin: 0 !important;
            }

            h5 {
              a {
                color: #000485;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                font-family: "TTCommons-Regular";
                margin: 0;
                text-decoration: none;
                border-bottom: 1px solid transparent;

                &:hover {
                  border-bottom: 1px solid #000485;
                }
              }

              span {
                color: #607790;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
              }
            }

            .row {
              + .row {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.chat-wrapper {
  .filter-wrap {
    margin: -60px 0 20px 0;
  }
}

.custom-table > .MuiPaper-root .thead-row td table tr td:first-child h5 {
  justify-content: flex-start;
}

.default-image-100 {
  width: 100px !important;
  height: 67px;
}

.default-preview-image {
  width: 300px !important;
  height: 240px !important;
  margin-left: 40px;
}

.scroll-fix {
  position: fixed;
  display: contents;
}

.img-wrapper {
  width: 100%;
  max-width: 120px;
  margin: 0 15px 0 0;
}

.webinar-img {
  margin: auto;
  display: block;
  max-width: 85%;
  height: 90px;
  max-height: 100%;
}

.checklist-categories {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 40%);

    li {
      margin: 0;

      a {
        padding: 24px 30px 18px;
        color: #000485 !important;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-size: 22px;
        font-family: "TTCommons-Medium";
        cursor: pointer;
        text-decoration: none;
        border: 0;
      }

      + li {
        border-top: 1px solid #d1dde5;
      }

      &.active {
        a {
          background: #0030b5;
          color: #fff !important;
        }
      }
    }
  }
}

.checklist-dnd {
  li {
    margin-bottom: 15px;
    list-style: none;

    .checklist-card {
      padding: 17px 20px 17px 20px;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 40%);

      .checklist-task {
        display: flex;
        align-items: flex-start;

        > span {
          margin: 0 15px 0px 0;
        }

        .checklist-control {
          width: calc(100% - 23px);
          display: flex;
          align-items: center;

          h5 {
            color: #8094ab;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 1.5px;
            line-height: 16px;
            font-family: "brandon_grotesquebold";
            text-transform: uppercase;
            margin: 0 0 7px 0;
          }

          .title {
            width: calc(100% - 80px);

            .primaryTask {
              color: #000485;
              font-size: 22px;
              font-family: "TTCommons-Medium";
              line-height: 28px;
              word-break: break-word;
            }

            a {
              color: #f76155;
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              text-align: right;
              border-bottom: 1px solid #f76155;
              text-decoration: none;
            }
          }

          .actions-div {
            opacity: 0;
            transition: all 0.4s;
            display: flex;

            span {
              display: flex;
              width: 40px;
              align-items: center;
              justify-content: center;
            }
          }

          &:hover {
            .actions-div {
              opacity: 1;
              transition: all 0.4s;
            }
          }

          .wizzard-form {
            margin-left: 120px;
            min-height: auto;

            .max-width {
              max-width: 100%;
              margin-bottom: 0 !important;
              margin-top: 15px;

              .add-task-actions {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                margin-bottom: 15px;

                button {
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.checklist-items {
  padding: 0px;
  list-style: none;
}

.resource-pad {
  padding-top: 6px;
}

.fix-height {
  max-height: 85px !important;
  max-width: 85px;
}

.checklist-tasks-wrapper .loader {
  position: initial !important;
  transform: none !important;
  margin: 50px auto 0 !important;
  display: flex;
  justify-content: center;
}

img.country-flag {
  height: 20px;
  width: 30px;
  border-radius: 3px;
}

.university-logo-wrapper {
  display: flex;
  align-items: center;
  margin-right: 12px;
  overflow: hidden;
  min-height: 30px;
  min-width: 55px;

  img {
    display: block;
    max-width: 80px;
    white-space: nowrap;
    max-height: 45px;
  }

  h3 {
    margin: 0;
    font-size: 18px;
    line-height: 19px;
    color: #000485;
  }
}
