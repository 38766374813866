@media (max-width: 1366px) {
    div {
        .OnboardingWrapper {
            padding-top: 80px;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            >div {
                width: 100%;
                max-width: 325px;
            }

            h1 {
                margin: 0 0 50px 0;
            }

            form {
                margin: 0px auto 80px;

                button {
                    font-size: 18px !important;
                    height: 48px;
                }
            }

            p {
                margin: 0 0 30px 0;
            }
        }

        .MuiInputBase-formControl {
            .MuiInputBase-input {
                padding: 14.15px 20px 10.15px;
                font-size: 16px;
                line-height: 20px;
                min-height: 48px;
            }

            textarea.MuiInputBase-input {
                padding: 10px;
            }
        }

        .custom-dropdowninput>span {
            top: 10px;
        }
    }
}

@media (max-width: 1024px) {
    div {
        h2 {
            font-size: 24px;
            line-height: 24px;
        }

        h3 {
            font-size: 20px;
            line-height: 20px;
        }

        .main-content {
            padding: 75px 15px 15px 65px;
            overflow-x: hidden;

            header {
                &.header {
                    width: calc(100% - 50px);
                    left: 50px;
                    padding: 8px 15px;
                    height: 60px;

                    .HeaderLeft {
                        width: 50%;
                    }

                    .HeaderRight {
                        a {
                            background: #e5eaf7;
                        }
                    }
                }
            }

            .nav-sidebar {
                width: 50px !important;
                z-index: 1;

                .nav-header {
                    min-height: 62px;
                    margin-bottom: 14px;

                    a {
                        width: 35px !important;
                    }
                }

                .navmenu {
                    height: calc(100% - 75px);

                    .left-menu {
                        padding: 0 8px;

                        ul.left-ul {
                            li {
                                a {
                                    height: 34px;
                                    width: 34px;
                                }
                            }
                        }

                        .left-ul.bottom {
                            left: 8px !important;
                            bottom: 80px !important;
                        }
                    }

                    .right-menu {
                        padding-left: 4px;
                        padding-right: 4px;

                        h3 {
                            font-size: 0;
                            line-height: 0;
                            margin: 0;
                        }

                        ul {
                            li {
                                a {
                                    font-size: 0;
                                    padding: 0;
                                    width: 42px;
                                    height: 42px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        margin: 0;
                                    }
                                }

                                +li {
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }

            &.Sidebar-open {
                padding: 72px 15px 15px 65px;

                header.header {
                    width: calc(100% - 50px);
                    left: 50px;
                }

                .nav-sidebar {
                    width: 220px;

                    .left-menu {
                        ul.left-ul {
                            li {
                                .right-menu {
                                    left: 50px !important;
                                    padding: 0 5px !important;
                                    width: 168px !important;
                                }
                            }
                        }
                    }
                }

                .wizzard-content {
                    .wizard-button {
                        padding-left: 235px;
                    }
                }
            }

            .page-content-wrap {
                .welcome-page {
                    h2 {
                        margin: 0 0 15px 0;
                    }

                    .lookingFor {
                        margin: 0 0 15px 0;

                        .col-md-8 {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        h3 {
                            margin: 0 0 15px 0;
                        }

                        ul {
                            font-size: 15px;
                            line-height: 17px;

                            li {
                                margin: 0 0 15px 0;
                            }
                        }
                    }

                    .platform-div {
                        h3 {
                            font-size: 18px;
                            line-height: 20px;
                            margin: 0 0 15px 0;
                        }

                        .bg-div {
                            padding: 15px 15px;
                            min-height: 140px;

                            h1 {
                                font-size: 24px;
                                line-height: 30px;
                            }

                            p {
                                font-size: 15px;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }
        }

        .page-header {
            margin: 0 0 10px 0;
            min-height: 32px;

            h3 {
                font-size: 24px;
                line-height: 24px;
            }

            .import-options {
                img {
                    width: 32px;
                }
            }

            .tabs-div {
                margin-left: 15px;

                div {
                    font-size: 18px;
                    margin: 0 15px 0 0 !important;
                    padding: 0px 0 0 0 !important;
                }
            }
        }

        .filter-wrap {
            margin: 0 0 15px 0;

            .filter-left {
                a {
                    min-width: 32px;
                    min-height: 32px;
                    max-width: 32px;
                    max-height: 32px;
                    overflow: hidden;

                    img {
                        width: 16px;
                    }

                    +.selected {
                        img {
                            width: 36px;
                        }
                    }
                }

                .FilterSearchBox {
                    +.selected {
                        img {
                            width: 36px;
                        }
                    }
                }
            }
        }

        .FilterMainWrap {
            padding: 0 0;

            .FilterBgWrap {
                padding: 15px 15px 10px 15px;
                margin: 0 auto 15px;

                .FilterTitleRow {
                    margin: 0 0 15px 0;
                }
            }
        }

        .year-checkbox-ul {
            li {
                width: 20%;
            }
        }

        .filter-left {
            flex-wrap: wrap;

            .FilterSearchBox {
                margin: 0 5px 0 5px;

                .MuiInputBase-formControl {
                    .MuiInputBase-input {
                        min-width: 180px;
                        font-size: 14px;
                    }
                }

                +.FilterSorting {
                    margin: 0 0 0 38px;
                    width: 100%;
                }
            }
        }

        .filter-right {
            >div {
                +div {
                    margin-left: 15px;
                }

                .MuiButton-root.btn {
                    padding: 9.65px 10px 7.65px;
                }
            }
        }

        .MuiButton-root {
            &.btn {
                font-size: 15px;
                line-height: 15px;
                min-height: 34px;
                min-width: 110px;
            }
        }

        .custom-table {
            margin: -10px -5px 0;

            >.MuiPaper-root {
                padding: 0 0;

                >table {
                    border-spacing: 5px 8px !important;
                    min-width: 800px;
                }

                .thead-row {
                    td {
                        table {
                            tr {
                                td {
                                    padding: 10px 10px 10px;
                                    word-break: break-word;

                                    &:first-child {
                                        padding-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .tbody-row {
                    box-shadow: 0 0px 2px 5px rgba(209, 221, 229, 0.1);

                    td {
                        table {
                            tr {
                                td {
                                    padding: 10px 10px 6px;
                                    min-height: auto;
                                    font-size: 12px;
                                    line-height: 15px;
                                    word-break: initial;
                                    white-space: normal;
                                    white-space: normal;
                                    word-break: break-word;

                                    &:first-child {
                                        padding-left: 10px;
                                    }

                                    &:last-child {
                                        padding-left: 10px;
                                    }

                                    .table-actions {
                                        opacity: 1;

                                        img {
                                            width: 28px;
                                            height: 28px;
                                        }
                                    }
                                }
                            }
                        }

                        .collapse-table {
                            tr {
                                td {
                                    padding: 12px 10px 10px;

                                    .row {
                                        flex-wrap: nowrap;
                                    }

                                    &:first-child {
                                        width: 0 !important;
                                    }

                                    &:last-child {
                                        width: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.empty-table {
                >.MuiPaper-root {
                    table {
                        min-width: auto;

                        .EmptyMessage {
                            margin: 30px 0 0 0;

                            h2 {
                                font-size: 24px;
                                line-height: normal;
                                max-width: 250px;
                                margin: 0 auto 15px;
                            }

                            h3 {
                                font-size: 18px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }

        .homefeed {
            .homefeed-content {
                .homefeed-item {
                    padding: 15px 15px 15px;

                    .text-content {
                        h5 {
                            font-size: 12px;
                            margin: 0 0 8px 0;
                        }

                        h3 {
                            margin: 0 0 10px 0;
                        }

                        h6 {
                            margin: 0 0 10px 0;
                        }

                        h6:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .actions-div {
                        opacity: 1;
                    }
                }
            }
        }

        .profile-content {
            .page-title {
                margin-bottom: 30px;
            }

            .profile-form {
                max-width: 100%;

                .row {
                    margin: 0 -15px;

                    .col-md-6 {
                        padding: 0 15px;
                    }
                }
            }
        }

        .wizzard-content {
            padding-bottom: 80px !important;

            .back-div {
                position: initial;
                margin-bottom: 20px;
            }

            .wizzard-form {
                min-height: calc(100vh - 285px);
            }

            .wizard-button {
                padding: 15px 0 0;
                position: fixed;
                margin: 0;
                width: 100%;
                left: 0;
                right: 0;
                padding-left: 50px;
                bottom: 0;
                background: #fff;
                padding-bottom: 15px;
                z-index: 0;
                padding-left: 50px;
            }
        }

        .single-select-wrap {
            .single-select-content {
                >div {
                    margin-bottom: 10px;
                }
            }
        }

        .multiselect-wrap {
            .multiselect-content {
                >div {
                    width: 33.33%;
                    max-width: 100%;
                    padding-bottom: 10px;

                    .MuiPaper-root {
                        height: 100%;
                    }
                }
            }
        }

        .post-card {
            .box-wrap {
                padding: 10px;
            }
        }

        .custom-dropdownul {
            padding: 10px;
        }

        .webinar-registerd {
            .box-wrap {
                padding: 15px;

                .left {
                    min-width: 140px;
                    max-width: 140px;
                    padding-right: 10px;
                    padding-left: 0;
                }

                .right {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 0;
                    padding-left: 10px;

                    .rounded-btn {
                        position: initial;
                        margin-top: 10px;
                        transform: none !important;
                    }
                }
            }

            &.webinar-student-services {
                .box-wrap {
                    .right {
                        .rounded-btn {
                            transform: none !important;
                        }

                        h4 {
                            img.star {
                                margin: -4px 0 0 6px;
                            }
                        }
                    }
                }
            }

            &.webinar-job-intern {
                .box-wrap {
                    .left {
                        margin: 0 0px;
                    }

                    .right {
                        width: calc(100% - 50px);
                        padding-left: 15px;

                        .rounded-btn {
                            button {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .drag-wrapper {
        .drag-content-wrap {
            .bg-wrap {
                .drag-main-row {
                    height: auto;

                    .drag-item {
                        padding: 12px 15px 10px;

                        .left {
                            width: calc(100% - 64px);

                            .img-wrap {
                                max-width: 50px;
                            }
                        }

                        .drag-icon {
                            margin: 0 15px 0 0;
                        }

                        .actions-div {
                            opacity: 1;

                            a {
                                min-width: 32px;

                                svg {
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                    }
                }

                .drag-collapse-row {
                    padding: 0 15px 0 15px;
                }
            }
        }

        +.drag-wrapper {
            margin: 10px 0 0 0;
        }
    }

    .collapse-item {
        padding: 0 0 0 0;

        +.collapse-item {
            margin: 10px 0 0 0;
            padding-top: 10px;
        }

        &:last-child {
            padding-bottom: 10px;
        }

        .resources-img-wrap {
            margin: 0 10px 0 0;
        }

        .actions-div {
            opacity: 1;

            a {
                min-width: 32px;

                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .country-insights-table-wrapper {
        >.custom-table {
            >.MuiPaper-root {
                >table {
                    min-width: 1100px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    div {
        .main-content {
            .page-content-wrap {
                .welcome-page {
                    .platform-div {
                        +.platform-div {
                            .row {
                                flex-wrap: wrap;

                                .col-md-2 {
                                    flex: 0 0 33.33%;
                                    max-width: 33.33%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }

            .table-pagination {
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 15px;

                .pagination-dropdown {
                    width: 100%;
                    justify-content: center;
                }

                .MuiPagination-root {
                    width: 100%;

                    .MuiPagination-ul {
                        margin: 10px 0 10px 0px !important;
                        justify-content: center;

                        li {
                            margin: 0 2px;

                            button {
                                height: 30px;
                                min-width: 30px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    body {
        .categories-grid {
            .MuiGrid-grid-xs-3 {
                max-width: 35%;
                flex-basis: 35%;
            }

            .MuiGrid-grid-xs-9 {
                max-width: 65%;
                flex-basis: 65%;
            }
        }

        &.checklist-dnd {
            li#checklist-category-li {
                a {
                    >div {
                        margin-right: 8px !important;

                        img {
                            width: 10px;
                        }
                    }

                    &.link {
                        font-size: 18px;
                        padding: 13px 10px 10px;
                    }
                }
            }

            #checklist-li {
                .checklist-card {
                    padding: 13px 10px 10px 10px;

                    .checklist-task {
                        >span {
                            margin: 0 8px 0px 0;

                            img {
                                width: 10px;
                            }
                        }

                        .checklist-control {
                            width: calc(100% - 18px);

                            .title {
                                width: calc(100% - 48px);

                                .primaryTask {
                                    font-size: 18px;
                                    line-height: 18px;
                                }

                                a {
                                    font-size: 15px;
                                }

                                .wizzard-form {
                                    margin-left: 0;
                                    min-height: auto;
                                    margin-right: -66px;
                                }
                            }

                            .actions-div {
                                opacity: 1;

                                a {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    div {
        .loader {
            margin-left: 25px !important;
        }

        .OnboardingWrapper {
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 15px;
            padding-right: 15px;

            .BrandLogo {
                margin: 0 auto 40px;

                img {
                    height: 30px;
                }
            }

            h1 {
                margin: 0 0 30px 0;
                font-size: 32px;
                line-height: 32px;
            }

            form {
                margin: 0px auto 30px;
            }

            p {
                margin: 0 0 30px 0;
                font-size: 15px;
                line-height: 16px;
            }

            .KeepMeSignin {
                margin: 25px 0 35px 0;
            }
        }

        .main-content {
            padding-top: 75px;

            header {
                &.header {
                    height: auto;
                    padding: 8px;

                    .HeaderLeft {
                        width: 100%;
                        padding-right: 8px;

                        .dropdown-wrapper {
                            .custom-dropdowninput {
                                input {
                                    min-height: 40px;
                                    padding: 12.15px 20px 8.15px;
                                }
                            }
                        }
                    }

                    .HeaderRight {
                        margin: 0 auto;

                        a {
                            margin: 0;
                            width: 40px;
                            height: 40px;
                            background: #eef1f9;

                            svg {
                                width: 16px;
                            }

                            +a {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }

            .nav-sidebar {
                z-index: 1;
            }

            &.Sidebar-open {
                padding: 75px 15px 15px 65px;

                .nav-sidebar {
                    width: 100%;
                    z-index: 1060;

                    .navmenu {
                        .left-menu {
                            padding: 0 15px;

                            .left-ul.bottom {
                                left: 15px !important;
                            }

                            ul.left-ul {
                                li {
                                    .right-menu {
                                        left: 64px !important;
                                        padding: 0 10px !important;
                                        width: 200px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                header {
                    &.header {
                        width: calc(100% - 50px);
                        left: 50px;
                    }
                }
            }

            .page-content-wrap {
                .welcome-page {
                    .lookingFor {
                        ul {
                            font-size: 14px;
                            line-height: 15px;

                            li {
                                width: 100%;
                                margin: 0 0 10px 0;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                img {
                                    &:first-child {
                                        width: 24px;
                                    }

                                    &:last-child {
                                        width: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .platform-div {
                        .row {
                            .col-md-2 {
                                flex: 0 0 100% !important;
                                max-width: 100% !important;
                                margin-bottom: 10px !important;

                                .bg-div {
                                    min-height: 100px;

                                    h1 {
                                        font-size: 30px;
                                        line-height: 32px;
                                    }

                                    p {
                                        font-size: 18px;
                                        line-height: 20px;
                                    }
                                }
                            }

                            .col {
                                flex-basis: initial;
                                width: 100%;

                                .bg-div {
                                    min-height: 100px;

                                    h1 {
                                        font-size: 30px;
                                        line-height: 32px;
                                    }

                                    p {
                                        font-size: 18px;
                                        line-height: 20px;
                                    }
                                }

                                +.col {
                                    margin-top: 12px;
                                }
                            }
                        }

                        +.platform-div {
                            margin: 12px 0 0 0;
                        }
                    }
                }
            }

            .page-header {
                flex-wrap: wrap;

                h3 {
                    text-align: center;
                    width: 100%;
                    font-size: 30px;
                    line-height: 32px;
                }

                .left {
                    width: 100%;
                    flex-wrap: wrap;
                }

                .import-options {
                    margin: 0 auto;
                }

                .tabs-div {
                    margin-left: 0;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    width: 100%;
                    justify-content: center;
                }
            }

            .filter-wrap {
                justify-content: center;
                flex-wrap: wrap;

                .filter-left {
                    flex-wrap: wrap;

                    a {
                        +.selected {
                            img {
                                width: 36px;
                            }
                        }
                    }

                    .FilterSearchBox {
                        width: calc(100% - 38px);
                        margin: 0 0 0 5px;

                        * {
                            width: 100%;
                        }

                        +.FilterSorting {
                            margin-left: 38px;
                        }

                        +a {
                            margin-top: 10px;
                        }

                        +.selected {
                            img {
                                width: 36px;
                            }
                        }
                    }

                    .FilterSorting {
                        margin: 12px 0px 0 10px;
                    }
                }

                .filter-right {
                    width: 100%;
                    justify-content: center;
                    margin-top: 10px;
                }
            }

            .FilterCheckList {
                ul {
                    li {
                        >div {
                            margin: 0 8px 0 0;
                        }
                    }
                }

                ul.admin-filters {
                    li {
                        width: 50%;
                        margin: 0 0 10px 0;
                    }
                }
            }

            .FilterMainWrap {
                .FilterBgWrap {
                    .FilterCheckList {
                        h6 {
                            margin: 0 0 10px 0;
                        }

                        .col-md-9 {
                            margin-top: 10px;
                        }
                    }
                }
            }

            .year-checkbox-ul {
                li {
                    width: 25%;
                }
            }

            .degree-checkbox-ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;
                    margin: 0 0 10px 0 !important;
                }
            }

            .country-insights-links-wrapper {
                .row {
                    flex-wrap: wrap !important;

                    +.row {
                        margin-top: 10px !important;
                    }
                }
            }

            .table-pagination {
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 15px;

                .pagination-dropdown {
                    width: 100%;
                    justify-content: center;
                }

                .MuiPagination-root {
                    width: 100%;

                    .MuiPagination-ul {
                        margin: 10px 0 10px 0px !important;
                        justify-content: center;

                        li {
                            margin: 0 2px;

                            button {
                                height: 24px;
                                min-width: 24px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .homefeed {
                .homefeed-content {
                    .homefeed-item {
                        flex-wrap: wrap;
                        position: relative;
                        padding: 10px;

                        .img-wrap {
                            max-width: 100%;
                            margin: 0 0 10px 0;
                            max-height: initial;

                            img {
                                height: auto;
                            }
                        }

                        .img-wrapper {
                            margin: 15px 0 15px 0;
                            text-align: center;
                            display: block;
                            max-width: 100%;
                        }

                        .text-content {
                            h3 {
                                font-size: 22px;
                                line-height: 22px;
                            }

                            p {
                                font-size: 16px;
                                line-height: 18px;
                            }

                            h6 {
                                font-size: 17px;
                                line-height: 19px;
                            }
                        }

                        .actions-div {
                            position: absolute;
                            top: 15px;
                            right: 15px;
                            background: #fff;
                            border-radius: 100px;
                            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                            padding: 0 4px;

                            a {
                                padding: 0 0px;
                                min-width: 24px;

                                svg {
                                    width: 24px;
                                    height: 24px;
                                }

                                img {
                                    width: 16px;
                                    height: 16px;
                                }

                                +a {
                                    border-left: 1px solid #f1f1f1;
                                }
                            }

                            span {
                                padding: 0 0px;
                                min-width: 24px;
                                width: auto;
                                height: auto;

                                &:first-child {
                                    img {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }

                                img {
                                    width: 24px;
                                    height: 24px;
                                }

                                +span {
                                    border-left: 1px solid #f1f1f1;
                                }
                            }
                        }
                    }
                }
            }

            .profile-content {
                form {
                    .profile-form {
                        h3 {
                            margin: 0 0 15px 0;
                        }

                        .row {
                            margin: 0;

                            .col-md-6 {
                                padding: 0;

                                +.col-md-6 {
                                    border-top: 1.5px solid #d1dde5;
                                    border-left: 0;
                                    margin-top: 30px;
                                    padding-top: 30px;
                                }
                            }
                        }
                    }

                    .profile-action {
                        margin-top: 30px !important;
                    }
                }
            }

            .MuiDialog-container {
                .MuiPaper-root {}
            }

            .wizzard-content {
                padding-bottom: 30px !important;

                .mb-30 {
                    margin-bottom: 15px !important;
                }

                .mt-30 {
                    margin-top: 15px !important;
                }

                .mb-50 {
                    margin-bottom: 30px !important;
                }

                .mt-50 {
                    margin-top: 30px !important;
                }

                .back-div {
                    position: initial;
                    width: 100%;
                    margin: 0 0 20px 0;
                }

                .page-title {
                    margin-bottom: 15px !important;
                    font-size: 22px;
                    line-height: 22px;
                }

                form {
                    padding-bottom: 30px;
                }

                .wizzard-form {
                    min-height: calc(100vh - 278px);
                }

                .wizard-button {
                    padding: 10px 0 0;
                    padding-bottom: 10px;
                    padding-left: 50px !important;
                    z-index: 1;

                    button {
                        min-width: 100px;
                        margin: 0 5px;
                    }
                }
            }

            .single-select-wrap {
                h3 {
                    margin: 0 0 20px 0;
                }

                .single-select-content {
                    margin: 0 -5px;

                    >div {
                        width: 50%;
                        max-width: 100% !important;
                        padding: 0 5px;
                        margin-bottom: 5px;

                        >div {
                            height: 100%;
                        }

                        .MuiPaper-root {
                            padding: 15px;

                            h6 {
                                font-size: 16px;
                                line-height: 16px;
                            }

                            p {
                                font-size: 13px;
                                line-height: 13px;
                            }
                        }
                    }
                }
            }

            .multiselect-wrap {
                .multiselect-content {
                    margin: 0 -5px;

                    >div {
                        width: 50%;
                        padding: 0 5px 10px;
                        margin-bottom: 0 !important;

                        >div {
                            height: 100%;
                        }

                        .MuiPaper-root {
                            padding: 15px;

                            h6 {
                                font-size: 16px;
                                line-height: 16px;
                            }

                            p {
                                font-size: 13px;
                                line-height: 13px;
                            }
                        }
                    }
                }
            }

            .dropdown-array-option {
                .MuiChip-root {
                    border-radius: 100px;
                    padding: 6px 6px 6px 6px;
                    margin: 2px 2px 2px;
                    font-size: 12px;
                    line-height: 14px;

                    svg {
                        margin: 0px 0 0 0;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    .drag-wrapper {
        .drag-content-wrap {
            .bg-wrap {
                .drag-main-row {
                    height: auto;

                    .drag-item {
                        padding: 10px 10px 8px;

                        .left {
                            width: calc(100% - 64px);
                            flex-wrap: wrap;

                            .drag-icon {
                                margin: 0 10px 0 0;

                                img {
                                    width: 10px;
                                }
                            }

                            .img-wrap {
                                margin: 0 10px 0 0;
                                max-width: 30px;
                                border-radius: 4px;
                                overflow: hidden;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
                                min-width: 30px;
                            }

                            h3 {
                                font-size: 18px;
                                line-height: 18px;
                                width: calc(100% - 50px);
                                margin: 0;
                            }

                            a {
                                width: 100%;
                                margin: 2px 0 0 20px;
                            }
                        }
                    }
                }

                .drag-collapse-row {
                    border-top: 1.5px solid #e8eef2;
                    padding-top: 15px;
                }
            }
        }

        +.drag-wrapper {
            margin: 10px 0 0 0;
        }
    }

    .student-services-categories {
        .bg-wrap {
            .drag-main-row {
                .drag-item {
                    .left {
                        h3 {
                            width: calc(100% - 70px) !important;
                        }

                        a {
                            margin: 0px 0 0 62px !important;
                        }
                    }
                }
            }
        }
    }

    .collapse-item {
        padding: 0 0 0 20px;
        flex-wrap: wrap;
        position: relative;

        .drag-icon {
            margin: 0 0px 0 0;
            position: absolute;
            left: 0;
            top: 15px;

            img {
                width: 10px;
            }
        }

        +.collapse-item {
            margin: 15px 0 0 0;
            padding-top: 15px;
        }

        &:first-child {
            padding-bottom: 10px;

            .drag-icon {
                top: 0px;
            }
        }

        &:last-child {
            padding-bottom: 15px !important;
        }

        .img-wrap {
            max-width: 100%;
            margin: 0 0 15px 0;

            img {
                width: 100%;
                max-width: 100%;
            }
        }

        .resources-img-wrap {
            margin: 0 0px 10px 0;
            width: 100% !important;
            height: auto !important;

            &.normal-image {
                img.default-image-100 {
                    max-width: 100px;
                    display: block;
                    margin: 0 auto;
                }
            }

            img {
                object-fit: initial;
                height: auto;
            }

            video {
                height: auto !important;
                width: 100% !important;
            }

            .img-wrap {
                width: 100%;
                max-width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                }
            }
        }

        .collapse-content {
            width: 100%;
            margin-top: 15px;

            h4 {
                font-size: 18px;
                line-height: 18px;
            }

            p {
                font-size: 16px;
                line-height: 18px;
            }
        }

        .actions-div {
            position: absolute;
            top: 10px;
            right: 10px;
            background: #fff;
            border-radius: 100px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            padding: 0 4px !important;

            a {
                padding: 0 0px;
                min-width: auto !important;

                img {
                    width: 24px;
                    height: 24px;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    body {
        .categories-grid {
            .MuiGrid-grid-xs-3 {
                max-width: 100%;
                flex-basis: 100%;
            }

            .MuiGrid-grid-xs-9 {
                max-width: 100%;
                flex-basis: 100%;

                .EmptyMessage {
                    margin: 15px 0 0 0;
                }
            }
        }

        &.checklist-dnd {
            .checklist-category-name {
                position: initial;
                width: 100%;
                text-align: center;
                margin: 15px 0 5px 0;
            }

            .checklist-categories {
                ul {
                    margin-bottom: 0;
                }
            }

            #checklist-li {
                .checklist-card {
                    .checklist-task {
                        align-items: flex-start;

                        .checklist-control {
                            align-items: flex-start;

                            .title {
                                .wizzard-form {
                                    form {
                                        padding-bottom: 0;

                                        .max-width {
                                            margin-top: 0;

                                            .row {
                                                >div {
                                                    +div {
                                                        margin-top: 15px !important;
                                                    }
                                                }
                                            }

                                            .add-task-actions {
                                                margin-top: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Toastify {
        .Toastify__toast-container {
            padding: 0 15px !important;
            bottom: 15px !important;

            .Toastify__toast {
                padding: 15px 15px 12px !important;
            }
        }
    }

    .country-insights-table-wrapper {
        >.custom-table {
            >.MuiPaper-root {
                >table {
                    min-width: 1250px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    div {
        .MuiDialog-container .MuiPaper-root {
            padding: 25px;
        }

        .PresenterDiv {
            .PresenterDiv-row {
                >div {
                    max-width: 100%;

                    +div {
                        margin-top: 10px;
                    }
                }
            }
        }

        .timeZoneselect {
            flex-wrap: wrap;

            .form-group {
                max-width: 100%;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .webinar-registerd {
            .box-wrap {
                flex-wrap: wrap;

                .left {
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                    margin: 0 0 15px 0;

                    img {
                        width: auto;
                    }
                }

                .right {
                    width: 100%;
                    max-width: 100%;
                    padding-left: 0;
                }
            }
        }
    }
}