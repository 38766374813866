// .form-group+.form-group {
//     margin: 15px 0 0;
// }

.MuiFormControl-root {
  .MuiInputBase-input {
    text-align: center;
    //z-index: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 45px;
    border-color: #d1dde5;
    border-width: 2px;
    background-color: #ffffff;
  }
}

.MuiTypography-h4,
.MuiTypography-h6,
.MuiTypography-h5 {
  color: #000485;
}

.upload-compnay-logo {
  margin: 8px auto !important;
}

.small-fileupload-wrap {
  .fileupload-custom {
    .dropzone {
      max-width: 111px;
      max-height: 111px;
      min-height: 111px !important;
      border-radius: 100%;
      margin: 30px auto 10px auto !important;
    }
  }
}

.small-fileupload-preview {
  .fileupload-custom {
    //box-shadow: 0px 0px 3px -1px rgba(68, 68, 68, 0.6);
    .dropzone {
      border: 2px solid #f1f4f7;
      background: #ffffff;
    }
  }
}

.step-footer-button {
  border-top: 2px solid 2px solid #e8eef2;
  padding: 1.5rem;
}

.profile-form {
  &.wizzard-form {
    .MuiGrid-spacing-xs-10 {
      margin-top: 0;
      margin-bottom: 0;
    }
    .pipeline {
      border-left: 1.5px solid #d1dde5;
    }
  }
}

.btn.evenl-btn-width {
  width: 50%;
}

.form-group {
  &.custom-dropdown-logo {
    .custom-dropdown-icon {
      max-width: 40px;
      max-height: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      margin-top: 11px;
      margin-left: 11px;
      border: 2px solid #f1f4f7;
      background: #ffffff;
      .logo-img {
        width: 100%;
        width: 100%;
      }
    }

    .MuiInputBase-formControl {
      .MuiInputBase-input {
        padding-left: 60px;
      }
    }
  }
}

.MuiChip-clickable {
  &.statusbox {
    min-width: 92px;
    font-size: 14px;
    &.hired {
      background-color: rgba(0, 48, 181, 0.2);
      color: #000485;
    }
    &.screened {
      background-color: #f1f4f7;
      color: #607790;
    }
    &.multiple {
      background-color: transparent;
      color: #000485;
    }
    &:hover {
      background-color: rgba(0, 48, 181, 0.1);
      color: #000485;
    }
  }
}
