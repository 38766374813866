body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.primaryColor {
  color: #000485;
}

.tablewith-avatar,
.employer-with-avatar {
  .avatar-item {
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 18px;
      line-height: 19px;
      color: #000485;
    }

    .img-wrap {
      border: 1px solid #f1f4f7;
      border-radius: 50%;
      display: flex;
      margin-right: 12px;
      overflow: hidden;
      max-width: 45px;
      height: 45px;

      &.img-wrap-sm {
        width: 25px;
        height: 25px;

        .MuiAvatar-root {
          width: 25px !important;
          height: 25px !important;
          font-size: 16px !important;
        }
      }
    }

    .post-img {
      margin: auto;
      display: block;
      width: 100%;
    }
  }

  .thead-row.extrawidth,
  .tbody-row.extrawidth {
    td {
      table {
        td {
          &:first-child {
            width: 13%;
            /* padding-left: 0px !important; */
          }

          .MuiCheckbox-root {
            svg {
              fill: #bfced8 !important;
            }

            &.Mui-checked svg {
              fill: #000485 !important;
            }
          }
        }
      }
    }
  }

  &.jobs {
    table {
      td {
        &:nth-child(1) {
          width: 230px;
        }

        &:nth-child(2) {
          width: 350px;
        }

        &:nth-child(4) {
          width: 120px;
        }

        &:nth-child(6) {
          width: 180px;
        }
      }
    }
  }

  &.activeEmpList {
    table {
      td {
        &:nth-child(2) {
          width: 350px !important;
        }

        &:nth-child(3) {
          width: 80px !important;
        }

        &:nth-child(4) {
          width: 100px !important;
        }

        &:nth-child(5) {
          width: 165px !important;
        }

        &:nth-child(6) {
          width: 165px !important;
        }

        &:nth-child(7) {
          width: 120px !important;
        }
      }
    }
  }

  &.requestEmpList {
    table {
      td {
        &:nth-child(2) {
          width: 300px !important;
        }

        &:nth-child(3) {
          width: 125px !important;
        }

        &:nth-child(4) {
          width: 150px !important;
        }

        &:nth-child(5) {
          width: 150px !important;
        }

        &:nth-child(6) {
          width: 50px !important;
        }

        &:nth-child(7) {
          width: 90px !important;
        }
      }
    }
  }

  &.userlist {
    table {
      td {
        &:nth-child(2) {
          width: 350px;
        }

        &:nth-child(3) {
          width: 130px;
        }

        &:nth-child(4) {
          width: 130px;
        }

        &:nth-child(5) {
          width: 200px;
        }
      }
    }
  }
}

.tablewith-avatar {
  .avatar-item {
    .img-wrap {
      display: table;
    }
  }
}
.page-header {
  justify-content: space-between;
}

.EmployersWrapper {
  background: #000485 url(./assets/svg/Employer-LogIn.svg) no-repeat center top !important;
  background-size: cover !important;
  min-height: 100vh;

  .KeepMeSignin {
    margin-top: 10px !important;
    margin-bottom: 35px !important;

    span {
      color: #ffffff !important;
    }
  }

  h1 {
    margin-bottom: 40px !important;
    color: #ffffff !important;
  }

  .forgotPassword {
    margin-bottom: 78px !important;

    a {
      color: #ffffff !important;
    }
  }

  .label {
    color: #ffffff !important;
  }

  .MuiButton-root.btn.btn-active {
    background-color: #0030b5 !important;
    border-color: #0030b5 !important;
  }

  h2 {
    color: #ffffff !important;
  }

  form {
    margin-bottom: 110px !important;
  }
}

//  img, svg {
//     vertical-align: middle !important;
//   }
.FilterCheckList {
  ul {
    &.employer-filters {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 15px;

      li {
        width: 25%;
        margin: 0 0 -10px -11px;
      }

      svg {
        fill: #bfced8 !important;
      }
    }
  }

  a {
    color: #0030b5;
    border-bottom: 1px solid;
    font-family: "TTCommons-Regular";
    font-size: 15px;
  }

  .custom-dropdownul ul li {
    margin: 0px 0 0 0px;
  }

  div .custom-dropdowninput>span {
    top: 10px;
  }

  div .MuiInputBase-formControl .MuiInputBase-input {
    padding: 14.15px 20px 10.15px;
    font-size: 16px;
    line-height: 20px;
    min-height: 48px;
  }
}

.statusbox {
  &.mini {
    min-width: 82px !important;
    height: 25px;
    background-color: #e5eaf7;
  }

  &.multiple {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    font-family: "TTCommons-Regular";
    color: #000485;
    background-color: #fff;
  }
}

.JobApplicatsMessage {
  text-align: center;
  color: #000485;

  h4 {
    font-size: 18px;
    margin: 6px 0 10px 0;
  }

  h5 {
    font-size: 22px;
    margin-top: 20px;
    font-weight: 600;
  }
}

.Signup {
  form {
    max-width: initial !important;
    margin-bottom: 50px !important;
  }

  .big-btn {
    max-width: 325px;
    margin: 50px auto;

    form {
      margin-bottom: 0 !important;
    }
  }

  .fileupload-custom,
  .page-subTitle {
    color: #ffffff;
  }

  .back-div {
    text-align: center;

    a {
      color: #ffffff;
    }
  }

  img,
  svg {
    vertical-align: middle !important;
  }

  h4 {
    font-size: 30px;
    line-height: 30px;
    margin-top: 50px;
  }

  &.vh-100 {
    height: 100vh;
  }
}

.EmployerMenu {
  .MuiMenu-list {
    width: 160px;

    li {
      display: flex;
      justify-content: center;
      font-size: 15px;
      color: #607790;
    }
  }
}

.filter-right.export-action img {
  vertical-align: middle;
}

.tox-tinymce {
  border: 2px solid #d1dde5 !important;
  border-radius: 10px !important;
}

.main-content {
  .nav-sidebar {
    width: 200px !important;

    .navmenu {
      .right-menu {
        width: 100% !important;
        border-left: none;
      }
    }
  }

  &.Sidebar-open {
    padding: 80px 24px 20px 224px;

    header.header {
      width: calc(100% - 200px);
      left: 200px;
    }
  }
}

.applicants-table {
  &.thead-row {
    table {
      td {
        &:nth-child(1) {
          width: 16% !important;
        }
        &:nth-child(2) {
          width: 11%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        /* &:nth-child(5) {
          width: 7%;
        }
        &:nth-child(6) {
          width: 12%;
        } */
      }
    }
  }
}

.jobapplicants-table {
  &.thead-row {
    table {
      td {
        &:nth-child(2) {
          width: 22%;
        }

        &:nth-child(4) {
          width: 22%;
        }
      }
    }
  }
}

.social-iconsWrapper {
  display: flex;

  a {
    display: flex;
    align-items: center;

    span {
      margin-top: 4px;
      margin-left: 5px;
    }
  }
}

.editNbuttonWrapper {
  display: flex;
  align-items: start;

  a {
    &:nth-child(2) {
      position: relative;
      top: -7px;
    }
  }
}

.applicantstatus {
  .MuiInputBase-formControl {
    .MuiInputBase-input {
      border: none;
      border-radius: 45px !important;
      // padding: 19.85px 14px 15.15px;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-family: "TTCommons-Regular";
      opacity: 1;
      min-height: 25px;
      cursor: pointer;
      width: 125px;
    }
  }

  .custom-dropdowninput>span {
    position: absolute;
    right: 15px;
    top: 16px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    /* background-color: #ffffff; */
  }

  .custom-dropdownul {
    border: none;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 10px;
    height: auto;
    width: 160px;
    left: -15px;
    box-shadow: 0px 1px 14px rgba(209, 221, 229, 0.4);

    ul {
      li {
        justify-content: center;

        &:nth-child(1) {
          display: none;
        }
      }
    }
  }

  &.New {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        background-color: #99ace1 !important;
        color: #000485;
      }
    }
  }

  &.Hired {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        background-color: #d4dcf3 !important;
        color: #000485;
      }
    }
  }

  &.Screened {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        background-color: #f1f4f7 !important;
        color: #607790;
      }
    }
  }

  &.Interviewed {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        background-color: #d1dde5 !important;
        color: #607790;
        opacity: 0.75;
      }
    }
  }

  &.Offer {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        background-color: #edf0fa !important;
        color: #000485;
      }
    }
  }
}

.delete-applicant {
  display: flex;
  align-items: end;
  a {
    &:nth-child(2) {
      position: relative;
      top: -7px;
    }
  }
}

.back-url {
  position: absolute;
  left: 43px;
}

.companyimg {
  position: absolute;
  bottom: 12px;
  left: 15px;
  border: 1px solid #d3d3d3;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.post-flag {
  width: 22.5px;
  height: 15px;
  border-radius: 3px;
}

.companyDropdown {
  .custom-dropdowninput>span {
    display: none !important;
  }
}

.customTypography {
  font-size: 15px;
  font-family: "TTCommons-Medium" !important;
}

.small-fileupload-wrap {
  .fileupload-custom {
    .dropzone {
      max-width: 111px;
      max-height: 111px;
      min-height: 111px !important;
      border-radius: 100%;
      margin: 30px auto 10px auto !important;
    }
  }

  img,
  svg {
    vertical-align: middle !important;
  }
}

.small-fileupload-preview {
  .fileupload-custom {

    //box-shadow: 0px 0px 3px -1px rgba(68, 68, 68, 0.6);
    .dropzone {
      border: 2px solid #f1f4f7;
      background: #ffffff;
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.jobs {
  .job-name {
    font-family: "TTCommons-Medium";
    font-size: 15px;
  }

  .user-role {
    font-family: "TTCommons-Medium";
    font-size: 15px;
  }

  .user-name {
    font-family: "TTCommons-Regular";
    font-size: 12px;
  }

  .job-regularFont {
    font-family: "TTCommons-Regular";
    font-size: 15px;
  }

  .job-loc {
    font-family: "TTCommons-Regular";
    font-size: 14px;
  }

  &.thead-row {
    table {
      td {
        &:nth-child(1) {
          padding-left: 15% !important;
        }
      }
    }
  }

  .viewIcon {
    height: 49px;
    width: 49px;
  }

  .editdelIcon {
    height: 46px;
    width: 46px;
  }
}

.p-0 {
  padding: 0 !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.resetpassword {
  .MuiInputBase-formControl {
    .MuiInputBase-input {
      border: none;
    }
  }
}

.export-action {
  .export-button {
    height: 17px;
    width: 39px;
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;

    &:hover {
      color: #0030b5;
    }
  }
}

.main-text-reg {
  color: #000485;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.emptylist-icon {
  margin-top: -100px;
  width: 140px;
  margin-bottom: 30px;
}

.notification-settings {
  .panel-width {
    max-width: 515px;
    margin: 0 auto;
  }

  .text-content {
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
  }

  .main-text {
    text-align: center;
  }
}

.collapse-item {
  padding: 0 0 0 20px;
  flex-wrap: wrap;
  position: relative;

  .drag-icon {
    margin: 0 0px 0 0;
    position: absolute;
    left: 0;
    top: 15px;

    img {
      width: 10px;
    }
  }

  +.collapse-item {
    margin: 15px 0 0 0;
    padding-top: 15px;
  }

  &:first-child {
    padding-bottom: 10px;

    .drag-icon {
      top: 0px;
    }
  }

  &:last-child {
    padding-bottom: 15px !important;
  }

  .img-wrap {
    max-width: 100%;
    margin: 0 0 15px 0;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .collapse-content {
    width: 100%;
    margin-top: 15px;

    h4 {
      font-size: 15px !important;
      line-height: 18px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .actions-div {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border-radius: 100px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 0 4px !important;

    a {
      padding: 0 0px;
      min-width: auto !important;

      img {
        width: 24px;
        height: 24px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.e-form-transition-container {
  background: #ffffff;
  height: 100vh;
  width: 100%;
  position: absolute;
}
.application-list__avatar-wrapper {
  height: inherit !important;
  max-width: inherit !important;
  border-radius: 0px !important;
  border: 0 !important;
  .sb-avatar {
  }
}

.employers-list__avatar {
  height: inherit !important;
  max-width: inherit !important;
  border-radius: 0px !important;
  border: 0 !important;

  .sb-avatar {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.default-styles__override {
  height: inherit !important;
  min-height: inherit !important;

  width: inherit !important;
  max-width: inherit !important;
  min-width: inherit !important;
}

.default-react-avatar {
  border: 1.5px solid #f1f4f7;
  border-radius: 50%;
}

.university-default-react-avatar {
  border: 1.5px solid #f1f4f7;
  border-radius: 50%;

  img {
    object-fit: contain;
  }
}

.company-default-react-avatar {
  &.company-default-react-avatar__text {
    //border: 0 !important;

    .sb-avatar {
      box-shadow: none !important;
      padding: 0px !important;
      border-radius: 5px;
    }

    img {
      padding: 0px !important;
    }
  }

  border: 1.5px solid #f1f4f7;
  border-radius: 5px;

  img {
    object-fit: contain;
    padding-left: 4px;
    padding-right: 4px;
  }

  .sb-avatar {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 45px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.applicant-employer__logo {
  img {
    object-fit: contain;
  }
}
.jobs-listing__avatar {
  img {
    object-fit: contain;
  }
}

.users-listing__avatar {
  img {
    object-fit: contain;
  }
}
.relative {
  position: relative;
}
.info-icon {
  position: absolute;
  right: -25px;
  top: 20px;
}
.student-card-modal {
  .dialog-container {
    position: relative;
    .dialog-close-icon {
      position: absolute;
      top: -30px;
      right: -20px;
      cursor: pointer;
    }
  }
  
}