*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.help-center-title{
  color: #000485;
  width: 100%;
  margin: 0px 0 46px;
  display: flex;
  padding: 0;
  font-size: 42px;
  font-family: "TTCommons-DemiBold";
  line-height: 38px;
  justify-content: center;
}
.ShowResponsiveDiv {
  display: none;
}
.text-primary {
  color: #000485;
}
.text-white {
  color: #fff;
}
.text-gray {
  color: var(--gray-text-color);
}
.text-secondary {
  color: var(--secondary-color) !important;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Regular";
  text-decoration: none;
}
.text-center {
  text-align: center !important;
}
.bg-primary {
  background: #000485;
}
.bg-secondary {
  background: var(--secondary-color);
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-flow-column {
  flex-flow: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.m-1 {
  margin: 0;
}
.m-2 {
  margin: 10px;
}
.m-3 {
  margin: 20px;
}
.w-70 {
  width: 70px !important;
}
.font-25 {
  font-size: 25px !important;
}
.w-100 {
  width: 100% !important;
}
.ml-10 {
  margin-left: 10px;
}
.overflow-initial {
  overflow: initial;
}
.pos-relative {
  position: relative;
}
.border-0 {
  border: 0 !important;
}
.text-disable {
  opacity: 0.5;
}
.title-primary {
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 6px;
  word-break: break-word;
  margin: 0;
}
.header-primary {
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  word-break: break-word;
}
.sub-header-primary {
  color: #000485;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  display: block;
  width: 100%;
  word-break: break-word;
}
.text-grey {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 15px;
  color: var(--gray-text-color);
}
.cursor-pointer {
  cursor: pointer;
}
.MuiDialog-paperWidthMd {
  border-radius: 10px;
}
.form-label {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 17px;
  justify-content: left;
  padding: 0;
  margin: 0 0 6px 8px;
  width: 100%;
  color: #000485;
}
.form-control {
  width: 100% !important;
}
.MuiInputBase-formControl fieldset {
  border: 0 !important;
}
.z-index .react-custom-dropdown {
  z-index: 10;
}
.react-custom-dropdown > div {
  padding: 1px 14px 0;
}
.react-custom-dropdown .css-15ev9ge-control {
  border-radius: 45px;
}
.react-custom-dropdown > div {
  border: 2px solid var(--gray-outline-color);
  background-color: #ffffff !important;
  width: 100%;
}
.react-custom-dropdown.multi-select > div:last-child {
  border: 0 !important;
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue {
  margin: 0;
  border-radius: 10.5px;
  background-color: #e5eaf7;
  padding: 2px 8px 1px 9px;
  + .css-ri91xb-multiValue {
    margin-left: 5px;
  }
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue .css-kbftzx {
  padding: 0;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  color: #000485;
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue .css-xb97g8 {
  padding: 0 0 0 5px;
  margin: -2px 0 0;
}
.mt-25 {
  margin-top: 25px;
}
.mt-3 {
  margin-top: 3px !important;
}
.mt-6 {
  margin-top: 6px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-17 {
  margin-bottom: 17px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.view-all {
  text-decoration: none;
  border-bottom: 1px solid var(--secondary-color);
}
.custom-checkbox .MuiFormControlLabel-label {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Medium";
  display: block;
}
.custom-checkbox .round-ckeckbox {
  padding: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #000485;
  border-radius: 100px;
  outline: 0;
  box-shadow: none;
  margin: 0 10px 0 0;
}
.custom-checkbox .round-ckeckbox svg {
  display: none;
}
.custom-checkbox .round-ckeckbox.Mui-checked {
  background: #000485;
}
.custom-checkbox .round-ckeckbox.Mui-checked:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}
.checkbox-wrap {
  .custom-checkbox {
    margin: 0;
    .MuiCheckbox-root {
      &.Mui-focusVisible {
        box-shadow: 0 0 8px 2px #000485;
        + span {
          color: #000c3d !important;
        }
      }
    }
  }
}
.MuiGrid-spacing-xs-3 {
  margin: -10px;
  width: calc(100% + 20px);
  > .MuiGrid-item {
    padding: 10px;
  }
}
.sort-by {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.sort-by span.sortby {
  color: var(--gray-text-color);
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 15px;
  margin: 0 10px 0 0;
}
.sort-by > div {
  margin: 0;
}
.sort-by .react-custom-dropdown {
  min-width: 151px;
}
.sort-by .react-custom-dropdown > div {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  border: 0;
  min-height: 40px;
  height: auto;
  padding: 0 !important;
  width: 100%;
  min-width: 130px;
  flex-wrap: nowrap;
  border: 0 !important;
}
.sort-by .react-custom-dropdown > div .css-uk6wr9-ValueContainer {
  color: #000485;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  padding: 0;
  width: auto;
  display: inline-block;
  flex: initial;
  overflow: visible;
  width: 100%;
  padding-left: 20px;
  .css-bz7a82-placeholder {
    margin: 0;
  }
}
.sort-by .css-1hb7zxy-IndicatorsContainer {
  padding-right: 10px;
}
.sort-by .react-custom-dropdown > div .css-2p0v1i-singleValue {
  margin: 0;
}
.react-custom-dropdown > div .css-2p0v1i-singleValue {
  overflow: initial !important;
}
/** custome dropdown */
.react-custom-dropdown {
  border-color: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  // box-shadow: none;
  box-sizing: 0;
  .css-1wrpugs-control {
    &:hover {
      border-color: var(--gray-outline-color);
    }
  }
  .css-isfiuo-placeholder {
    padding-top: 3px;
    box-sizing: border-box;
  }
}
.custom-textbox {
  input,
  select {
    border: 2px solid var(--gray-outline-color);
    border-radius: 45px;
    background-color: #ffffff;
    padding: 21.65px 38.24px 18.65px 39.6px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px; // color: var(--gray-text-color);
    color: #000485 !important;
    font-family: "TTCommons-Medium";
    box-sizing: border-box;
    min-height: 60px;
    margin: 0;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--gray-text-color) !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--gray-text-color) !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--gray-text-color) !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--gray-text-color) !important;
    }
    // --Focus style
    &:focus {
      box-shadow: 0 0 8px 0 #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}
.onboarding-wrapper {
  .onboarding-header {
    margin-bottom: 40px;
  }
  button {
    margin: 25px auto 0;
  }
  .login-layout,
  .reset-password {
    max-width: 325px;
    margin: 0 auto;
    .display-error {
      padding: 0 !important;
      position: relative;
      margin: 15px 0 5px;
      display: block;
      .text-secondary {
        text-decoration: none;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
  }
  form {
    max-width: 325px;
    margin: 0 auto;
    .display-error {
      padding: 0 !important;
      position: relative;
      margin: 10px 0 5px;
      .text-secondary {
        text-decoration: none;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
  }
  .forgot-passwordlink {
    margin-top: 50px;
    text-decoration: none;
    border-bottom: 1px solid rgb(96, 119, 144);
    &:focus {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
}
// .custom-textbox input:focus,
// .custom-textbox input[value=""] {
//   color: #000485;
// }
.Mui-error input,
.Mui-error select,
.Mui-error textarea {
  border-color: var(--secondary-color);
  opacity: 1;
}
.custom-textarea {
  > div {
    margin: 0;
    padding: 0;
  }
  textarea {
    border: 2px solid var(--gray-outline-color);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    color: #000485 !important;
    font-family: "TTCommons-Regular" !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    // --Focus style
    &:focus {
      box-shadow: 0 0 8px 0 #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}
// --Focus style
// textarea {
//   &:focus {
//     box-shadow: 0 0 0px 2px #000485, inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
//   }
// }
/** React tag Input **/
/** Default Model Backgroud **/
.MuiBackdrop-root {
  background-color: rgba(128, 148, 171, 0.4);
  overflow: hidden;
}
.toast-bottom {
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 709px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: #0030b5;
  color: #ffffff;
  .toast {
    padding: 20px 40px 17px 30px;
    display: flex;
    justify-content: space-between;
    .msg {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
    .action {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      border-bottom: 1px solid #ffffff;
      cursor: pointer;
    }
  }
}
:focus {
  outline: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
.loader-main {
  text-align: center;
  & > div {
    display: inline-block;
  }
}
.dialogue-back-btn {
  height: 21px !important;
  width: 21px !important;
  margin: -5px 0 0 0 !important;
}
.dialogue-back-btn + span {
  margin: 0;
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.MuiOutlinedInput-input {
  &:focus {
    &::placeholder {
      color: transparent !important;
    }
  }
}
input {
  &:focus {
    &::placeholder {
      color: transparent !important;
    }
  }
}
.Toastify {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1301;
  .Toastify__toast-container--bottom-center {
    width: 100% !important;
    max-width: 709px !important;
    bottom: 31px;
    min-width: 709px !important;
    .Toastify__toast {
      box-shadow: none;
      border: 0;
      min-height: auto;
      border-radius: 10px;
      background-color: #0030b5;
      margin: 0;
      padding: 20px 40px 17.3px 30px;
      transform: none !important;
      + .Toastify__toast {
        margin-top: 10px;
      }
      .Toastify__toast-body {
        color: #ffffff;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0;
        padding: 3px 0 0;
        svg {
          display: none;
        }
      }
      button {
        opacity: 1;
        svg {
          width: 16px;
          height: 16px;
          right: -10px;
          position: relative;
        }
      }
    }
    .Toastify__progress-bar {
      visibility: hidden;
    }
  }
}
.checklist-dnd {
  > li {
    margin-bottom: 15px;
    list-style: none;
    .checklist-card {
      .card-content {
        padding: 17px 39px 17px 20px;
        .checklist-task {
          display: flex;
          align-items: flex-start;

          .checklist-control {
            display: flex;
            align-items: flex-start;
            width: 100%;

            .MuiRadio-root {
              padding: 0;
              margin: 0 15px;
            }

            .MuiIconButton-colorPrimary {
              &:hover {
                background-color: transparent;
              }
            }
            .MuiIconButton-root {
              &:hover {
                background-color: transparent;
              }
            }

            & span.Mui-checked + div {
              text-decoration: line-through;
              text-decoration-color: var(--secondary-color);
            }
            &.task-control {
              .btn-edit {
                opacity: 0;
              }
              .btn-close {
                opacity: 0;
              }
              &:hover {
                .btn-edit {
                  opacity: 1;
                }
                .btn-close {
                  opacity: 1;
                }
              }
            }

            .title {
              font-size: 22px;
              font-family: "TTCommons-Medium";
              width: 75%;
              padding: 0;
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              input[type="text"] {
                font-family: "TTCommons-Medium";
                border: 0;
                font-size: 22px;
                letter-spacing: 0;

                color: var(--gray-text-color);
                outline: 0 !important;
                padding: 0;
                width: 100%;
              }
              > div {
                &:first-child {
                  max-width: 100%;
                  width: 100%;
                }
                &:last-child {
                  max-width: 100%;
                  width: 100%;
                }
              }
              .link-text {
                display: inline-block;
                margin-left: 20px;
                color: #f76155;
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
                text-decoration: underline;
                margin-left: 0;
              }
              &.completed {
                > div.primaryTask {
                  text-decoration: line-through;
                }
              }
            }

            .subtitle {
              font-size: 18px;
              font-family: "TTCommons-Regular";
              width: 90%;
              color: #000485;
              input[type="text"] {
                font-family: "TTCommons-Regular";
                border: 0;
                font-size: 18px;
                letter-spacing: 0;
                color: var(--gray-text-color);
                outline: 0 !important;
                padding: 0;
                width: 100%;
              }

              &.completed {
                text-decoration: line-through;
              }
            }
            .btn-edit {
              font-size: 18px;
              font-family: "TTCommons-Medium";
              text-decoration: underline;
              margin-right: 50px;
              color: var(--secondary-color);
              cursor: pointer;
              min-width: auto;
              white-space: nowrap;
            }
            .btn-close {
              border: 0;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background: #e5eaf7;
              display: flex;
              justify-content: center;
              align-items: center;
              outline: none;
              cursor: pointer;
              padding: 0;
              flex: 0 0 30px;
              min-width: auto;
              img {
                width: 10px;
              }
            }
          }
          button {
            padding: 2px 0 0;
            min-width: 134px;
          }
        }
        .sub-tasks {
          list-style: none;
          padding: 0;
          margin-left: 60px;
          margin-top: 15px;

          li {
            margin-bottom: 25px;
            &:last-child {
              margin-bottom: 0;
            }
            .checklist-control {
              align-items: center;
              .ncheckbox-wrapper {
                margin: -4px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

.relative-div {
  position: relative;
  .breadcrumb-div {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    ul {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      display: flex;
      align-items: center;
      list-style: none;
      padding: 11px 20px;
      margin: 0;
      li {
        a {
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          text-decoration: none;
          border-bottom: 1px solid transparent;
        }
        span {
          color: var(--secondary-color);
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          border-bottom: 1px solid var(--secondary-color);
        }
        + li {
          padding-left: 30px;
          position: relative;
          &:before {
            content: " >> ";
            position: absolute;
            left: 10px;
            color: var(--gray-text-color);
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
.error-content {
  text-align: center;
  h2 {
    margin: 0;
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 42px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
  }
  h3 {
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    margin: 0;
  }
  .img-wrap {
    margin: 50px auto;
    max-width: 625px;
    img {
      width: 100%;
    }
  }
  button {
    max-width: 270px;
    border-radius: 45px;
    background-color: var(--secondary-color);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    padding: 21.85px 26px 16.85px;
    height: auto;
    margin: 30px 0 0;
    text-transform: capitalize;
    font-family: "TTCommons-Medium";
    &:hover {
      background: rgb(248, 120, 110);
    }
  }
}
.profile-items:nth-child(2) .profile-upload .profile-img-wrap {
  border-radius: 0;
  height: auto;
}
.profile-items:nth-child(2) .profile-upload .profile-img-wrap img {
  border-radius: 0 !important;
}
.feedback-dialog .issueType {
  max-width: 336px;
  margin: 0 auto;
}
.chat-group-dialog,
.create-topic-dialog,
.new-msg-dialog {
  .MuiDialogContent-root {
    overflow: hidden !important;
  }
}
.text-warning {
  margin: 0 0 25px;
  color: #ff5f59;
  font-family: "TTCommons-Regular";
  font-size: 16px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 16px;
}
.crop-btn {
  max-width: 248px !important;
  margin: 30px 0 0 0 !important;
  width: 100% !important;
  height: 60px !important;
  border-radius: 45px !important;
}
.remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .btn-upload {
    color: var(--secondary-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    border-bottom: 1px solid var(--secondary-color);
    text-decoration: none;
  }
}
.removeicon-center {
  margin: 0 10px !important;
  + .removeicon-center {
    margin: 10px 0 0 0 !important;
  }
}
.company-list {
  .MuiGrid-spacing-xs-3 {
    margin: 0;
    width: 100%;
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
    .MuiGrid-grid-xs-12 {
      padding-left: 0;
      padding-right: 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
.company .body-section .card-top .react-custom-dropdown > div {
  min-width: 180px;
}
.cropper-modal {
  background-color: #f8f6f6 !important;
  opacity: 0.5;
}
.cropper-view-box {
  //height: 66% !important;
  //width: 65% !important;
  box-shadow: 0 0 0 2px #f76155;
}
.clearallBtnWrap {
  margin: 30px 0 0;
  a {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px solid var(--gray-color);
    font-family: "TTCommons-Regular";
    display: inline-block;
    color: var(--gray-color);
    &:focus {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
}
.MuiDialog-container {
  .MuiDialogContent-root {
    .closeModal {
      position: absolute;
      top: 0;
      right: -5px;
      button {
        opacity: 1;
        background: transparent;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
}
.modal-cancellink {
  &:hover {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  &:focus {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
}
.gray-linktext {
  text-decoration: none;
  white-space: nowrap;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid var(--gray-text-color);
  color: var(--gray-text-color);
}
.star {
  a {
    display: flex;
    position: relative;
    img {
      opacity: 0.8;
      &.filled-star {
        opacity: 1;
      }
    }
    &:focus,
    &:hover {
      img {
        opacity: 1;
      }
    }
    // --Focus style
    // &:focus {
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     box-shadow: 0 0 0px 2px #000485, inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
    //     width: 30px;
    //     height: 30px;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //   }
    // }
  }
}
.nlink-secondary {
  color: var(--secondary-color);
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
  border-bottom: 1px solid var(--secondary-color);
  text-decoration: none;
}
.title-boxs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
  .title-primary {
    margin-right: 15px;
  }
}
.nlink-btn {
  font-family: "TTCommons-Medium";
  font-size: 18px;
  padding: 6px 8px 3px;
  box-shadow: none !important;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  border-radius: 100px;
  min-width: 140px;
  line-height: normal;
  vertical-align: middle;
  // --Focus style
  // &:focus {
  //   box-shadow: 0 0 0px 2px #000485, inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
  // }
}
.nbg-primary {
  background-color: #000485 !important;
  color: #fff !important;
  &:hover {
    opacity: 0.9;
  }
}
.nbg-secondary {
  background-color: var(--secondary-color) !important;
  color: #fff !important;
  &:hover {
    opacity: 0.9;
  }
}
.nbg-gray {
  background-color: var(--gray-color) !important;
  color: #fff !important;
  &:hover {
    opacity: 0.9;
  }
}
.nbtn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.datepicker-wrapper {
  position: relative;
  .react-datepicker-popper {
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    width: 100%;
    margin-left: 0;
  }
}
.timepicker-wrapper {
  position: relative;
  .react-datepicker-popper {
    left: 0 !important;
    top: 100px !important;
    transform: none !important;
    margin-left: 0;
    width: 100%;
    .react-datepicker__time-container {
      float: none;
    }
  }
}
.upload-signup-profile-pic {
  max-width: 250px;
  margin: 0 auto 100px;
  .face-note {
    margin-bottom: 15px;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    line-height: 18px;
  }
  .profile-img-wrap {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    overflow: hidden;
    margin: 0 auto 15px;
    img {
      width: 100%;
    }
  }
  .profile-upload-link {
    .removeicon-center {
      img {
        width: 18px;
        height: auto;
        margin: 0 10px 0 0;
      }
    }
  }
}
.mb-5 {
  margin-bottom: 5px !important;
}
.notification-loader {
  height: 100%;
  .d-flex {
    height: 100%;
  }
}
.ncheckbox-wrapper {
  margin: 0;
  .MuiRadio-root {
    padding: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #000485;
    border-radius: 100px;
    outline: 0;
    box-shadow: none;
    .MuiSvgIcon-fontSizeSmall {
      display: none;
    }
    &.Mui-checked {
      border: 0;
      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }
}
.note-text {
  display: flex;
  align-items: center;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  color: #000485;
  line-height: 18px;
  margin: 0 0 15px;
  img {
    width: 19px;
    margin: 0 8px 0 0;
  }
}

.event-modal-popup {
  .MuiDialog-paper {
    max-width: 370px !important;
  }
  .MuiDialogContent-root {
    padding: 0;
    width: 100% !important;
    max-width: 325px;
    text-align: center;
    .MuiTypography-root {
      padding: 30px !important;
      .closeModal {
        right: -15px !important;
      }
      .Event-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 30px !important;
        max-width: 160px;
        img {
          width: 100%;
        }
      }
      h3 {
        margin: 0 0 2px;
        font-family: "TTCommons-Medium";
        color: #000485;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
      }
      h4 {
        margin: 0 0 10px;
        font-family: "TTCommons-Regular";
        color: #000485;
        font-size: 18px;
        line-height: 19px;
        font-weight: normal;
      }
      h5 {
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        font-weight: normal;
        white-space: nowrap;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin: -4px 6px 0 0;
        }
      }
      p {
        color: #000485;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 15px 0 30px;
      }
      .model-body {
        .got-it-button {
          width: 100%;
          margin: 0;
          button {
            width: 100%;
            font-size: 22px;
            padding: 7px 15px 0;
            margin: 0 auto;
            border-radius: 100px;
            height: 60px;
          }
        }
      }
    }
  }
}

.popover-wrapper {
  background-color: rgba(128, 148, 171, 0.4);
  .MuiPopover-paper {
    max-width: 390px;
    padding: 20px 20px 16px;
    border-radius: 0 10px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 40%);
    p {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: left;
    }
  }
}

.npopover-wrapper {
  .MuiPopover-paper {
    max-width: 340px;
    border-radius: 10px 1px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    h2 {
      margin: 0 auto 10px;
      color: #000485;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      max-width: 280px;
    }
    p {
      text-align: center;
      max-width: 280px;
      margin: 0 auto 15px;
    }
    .got-it-button {
      text-align: center;
      button {
        display: inline-block;
        width: auto;
        padding: 1px 0 0;
        height: 30px;
        min-height: auto;
        min-width: 114px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  &.no-top-left-corner-popover {
    .MuiPopover-paper {
      border-radius: 0 10px 10px 10px !important;
    }
  }
}

// --Focus style
// .Mui-focusVisible.MuiSwitch-switchBase span.MuiIconButton-label {
//   box-shadow: 0 0 10px 2px #000485;
//   border-radius: 100px;
// }
.notification-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -84px;
  margin-bottom: 80px;
  padding-right: 50px;
  span.label {
    color: #000485;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 40px;
  }
}
.label-right-notification {
  margin-right: 84px !important;
}
.top-align-notification {
  margin-top: -75px;
}

// .MuiTooltip-popper {
//   .MuiTooltip-tooltip {
//     margin: 6px 0 0 18px;
//     border-radius: 4px;
//     padding: 6px;
//     font-size: 15px;
//     background: #0030b5;
//     color: #fff;
//   }
// }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.calendar-card {
  .fc-view {
    position: initial !important;
  }
  .fc-scroller.fc-scroller-liquid-absolute {
    position: initial !important;
    overflow: hidden !important;
  }
  .fc-scroller {
    overflow: hidden !important;
  }
  td.fc-daygrid-day {
    padding-bottom: 15px !important;
  }
  .fc-view-harness {
    padding: 0 !important;
  }
}
.collapse-icon {
  display: none;
}

// focus css
.link-focus:focus {
  color: #000c3d !important;
  border-color: #000c3d !important;
  .back-link {
    + span {
      border-bottom: 2px solid #000c3d;
      color: #000c3d;
    }
  }
}

// Outline css starts
body.accessibility .link-focus:focus {
  outline: 1px solid #000c3d;
  outline-offset: 2px;
}
// Outline css ends

.img-link {
  text-decoration: none !important;
  border: 0 !important;
  display: flex !important;
  align-items: center !important;
  img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  span {
    border-bottom: 1px solid #f76155;
    color:#f76155
  }
  &:focus {
    color: #000c3d !important;
    span {
      border-color: #000c3d !important;
      color: #000c3d !important;
    }
  }
}

.MuiButton-root {
  &:focus {
    box-shadow: 0 0 8px 5px #000c3d !important;
  }
}

.bg-gray {
  &:focus {
    background: var(--gray-color-btn-hover) !important;
    opacity: 1 !important;
  }
}

.bg-secondary {
  &:focus {
    background: var(--secondary-color-btn-hover) !important;
    opacity: 1 !important;
  }
}

.bg-light-blue {
  &:focus {
    background: #264fc0 !important;
    opacity: 1 !important;
  }
}

.focus-white {
  &:focus {
    box-shadow: 0 0 8px 5px #fff !important;
  }
}

.nlink-btn {
  &:focus {
    box-shadow: 0 0 8px 5px #000c3d !important;
    color: #fff !important;
  }
}

.MuiCardHeader-action {
  button {
    &:focus {
      box-shadow: 0 0 8px 5px #000c3d !important;
    }
  }
}

.switch-text {
  color: #000c3d !important;
  border-color: #000c3d !important;
  border-bottom: 1px solid #000c3d;
}

.ncustom-switch {
  .MuiSwitch-root {
    margin: -3px 0 0;
  }
  > span {
    border-bottom: 1px solid transparent;
    line-height: 18px;
  }
  &.switch-focus {
    .MuiSwitch-track {
      box-shadow: 0 0 8px 1px #000c3d !important;
    }
    .MuiSwitch-thumb {
      box-shadow: 0 0 4px 1px #000c3d !important;
      background-color: #000c3d;
    }
    .MuiSwitch-root {
      border-bottom: 0;
    }
    > span {
      border-bottom: 1px solid #000c3d;
      color: #000c3d;
    }
  }
}

.MuiRadio-root {
  &.Mui-focusVisible {
    svg {
      box-shadow: 0 0 4px 1px #000c3d !important;
      border-radius: 100px;
      fill: #000c3d;
    }
    + span {
      color: #000c3d;
      border-bottom: 1px solid #000c3d;
    }
  }
}

.custom-checkbox {
  .round-ckeckbox {
    &.Mui-focusVisible {
      box-shadow: 0 0 4px 1px #000c3d !important;
    }
  }
}

.img-focus {
  &:focus {
    + p {
      color: #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}

.circle-boxshadow {
  &:focus {
    box-shadow: 0 0 5px 3px #000c3d !important;
  }
}

.alert-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin: -4px 0 0 8px;
  img {
    width: 100%;
  }
  &:focus {
    box-shadow: 0 0 5px 2px #000c3d !important;
  }
}

.ncheckbox-wrapper {
  .Mui-focusVisible {
    box-shadow: 0 0 5px 2px #000c3d !important;
  }
}

.attachment-post,
.attatcment {
  .btn-upload {
    border-radius: 100px;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-top: -1px;
    img {
      margin: 0;
    }
    &:focus {
      color: #000c3d !important;
      box-shadow: 0 0 5px 3px #000c3d !important;
    }
  }
}

.link-focus {
  &:focus {
    .text-secondary {
      color: #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}

.social-icons {
  a {
    &:focus {
      .MuiAvatar-root {
        box-shadow: 0 0 8px 5px #000c3d !important;
      }
      span {
        color: #000c3d !important;
      }
    }
  }
}

.social {
  a {
    &:focus {
      color: #000c3d !important;
      span {
        box-shadow: 0 0 8px 4px #000c3d !important;
        border-radius: 100px;
      }
    }
  }
}

.form-control {
  &:focus {
    box-shadow: 0 0 5px 3px #000c3d !important;
  }
}

.back-link {
  width: 22px;
  margin: -8px 10px 0 0;
  + span {
    border-bottom: 2px solid transparent;
    line-height: 18px;
  }
}
.title-primary {
  a {
    .back-link {
      margin: -2px 10px 0 0;
    }
  }
}
.card-close-btn {
  cursor: pointer;
}

.nback-arrow-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;

  .back-link {
    margin: -4px 8px 0 0;
  }
}

.dashboard-post a {
  &:focus {
    img {
      box-shadow: 0 0 8px 4px #000c3d;
    }
  }
}

.edit-saved-search {
  .MuiPaper-root {
    overflow: visible;
  }
}

.focus-dots {
  &:focus {
    color: #000c3d !important;
  }
}

.filter-group {
  .MuiAccordionSummary-root {
    border-radius: 10px;
    &.Mui-focusVisible {
      box-shadow: 0 0 5px 3px #000c3d !important;
      background-color: #fff;
    }
  }
}

.modal-close-icon {
  display: flex;
  align-items: center;
  img {
    width: 28px;
  }
}

.card-close-btn {
  img {
    width: 16px;
  }
}

.resource-section {
  .MuiAccordionSummary-root {
    &.Mui-focusVisible {
      background: transparent;
      .MuiAccordionSummary-content {
        p {
          border-bottom: 1px solid #000c3d;
          color: #000c3d !important;
        }
      }
    }
  }
}

.accessibility-text {
  display: flex;
  align-items: center;
  .MuiRadio-root {
    margin: -4px 0 0;
  }
}
.cCOoAV {
  display: none !important;
}
.preview-url-post {
  text-decoration: none;
}
.preview-nbox-wrappe {
  margin: 30px 0 0;
  .img-wrap {
    img {
      width: 100%;
    }
  }
  h3 {
    margin: 15px 0 15px;
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
  h4 {
    margin: 0;
    color: #000485;
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
  h5 {
    margin: 0;
    color: var(--gray-text-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 15px;
  }
  .topic-preview-video-section {
    margin: 0 0 30px;
  }
  .title-align {
    margin-top: 10px !important;
  }
}

.petition-header {
  border-radius: 8px;
  &.Mui-focused {
    box-shadow: 0 0 8px 3px #000c3d;
    background: transparent;
  }
}

.spinner-div {
  > div {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(0, 4, 133) transparent rgb(0, 4, 133) rgb(0, 4, 133);
    border-image: initial;
    border-radius: 50%;
    animation: 0.75s linear 1s infinite normal none running eXzyYY;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sso-popup {
  padding: 0 !important;
  .sso-popup-content {
    padding: 50px;
    center {
      font-family: "TTCommons-Medium";
      font-weight: 500;
    }
    .university-form {
      margin: 30px 0 0 0 !important;
      padding: 0;
      .image-upload {
        margin: 0 0 50px;
        .dropzone {
          .MuiAvatar-root {
            width: 100px;
            height: 100px;
            margin: 0 auto 15px;
            background: #d1dde5;
          }
        }
        .remove-icon {
          flex-wrap: wrap;
          div {
            width: 100%;
          }
        }
      }
      button {
        margin-bottom: 30px !important;
        font-size: 22px;
        .crop-btn {
          margin: 0 0 30px 0 !important;
        }
      }
      .profile-img-wrap {
        width: 120px !important;
        height: 120px;
        margin: 0 0 15px 0;
        border-radius: 100px;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 0 !important;
        }
        .initial-avatar {
          width: 100%;
          height: 100%;
          font-size: 65px;
          padding-top: 20px;
        }
      }
    }
  }
}
.mobile-profile-popup {
  .socials {
    align-items: flex-start !important;
    a {
      display: inline-block;
      margin: 0 10px;
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      text-decoration: none;
      .social-item {
        width: 39px;
        height: 39px;
        margin: 15px auto 8px !important;
        border-radius: 100px;
        img {
          width: 100%;
        }
      }
      &:focus {
        color: #000c3d !important;
        .social-item {
          box-shadow: 0 0 8px 5px #000c3d !important;
        }
      }
    }
  }
  .link-focus {
    text-decoration: none;
    border-bottom: 1px solid var(--gray-text-color);
  }
}
.socials {
  align-items: flex-start !important;
  margin-top: 15px;
  a {
    display: inline-block;
    margin: 0 10px;
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    .social-item {
      width: 39px;
      height: 39px;
      margin: 15px auto 8px !important;
      border-radius: 100px;
      img {
        width: 100%;
      }
    }
    &:focus {
      color: #000c3d !important;
      .social-item {
        box-shadow: 0 0 8px 5px #000c3d !important;
      }
    }
  }
}

.notifaction-managesetting {
  .info-card {
    .card-content {
      .panel-info {
        .info-description {
          padding-right: 15px;
          .panel-tabs {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.hide-scroll {
  overflow: hidden;
}

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  > div {
    width: 100%;
    img:first-child {
      max-width: 280px;
      display: block;
      margin: 0 auto;
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #001a80;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.boost-profile-popup {
  .model-body {
    margin-top: 20px;
    .rating-box {
      margin-bottom: 60px;
      .sub-header-primary {
        margin: 30px auto 0;
        max-width: 336px;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
.topic-post {
  h3 {
    margin: 15px 0 15px;
    color: #000485;
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
  h4 {
    margin: 0;
    color: #000485;
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
}

.terms-popover-div {
  p {
    font-size: 20px !important;
    line-height: normal !important;
    + p {
      margin-top: 15px;
    }
  }
}

.webinar-section-title {
  .title-primary {
    &:first-child {
      border-bottom: 3.5px solid var(--secondary-color);
      padding-bottom: 4px;
    }
    a {
      color: #000485;
      text-decoration: none;
      &:focus {
        border-bottom: 3.5px solid var(--secondary-color);
      }
    }
  }
}
.keep-me-signin.tnc-wrapper {
  max-width: 100%;
  .tnc-link {
    text-align: left;
  }
}
.tnc-link {
  margin: 0;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 18px;
  font-family: "TTCommons-Regular";
  color: #000485;
  font-weight: 400;
  a {
    color: var(--secondary-color);
    text-decoration: none;
  }
}
.tnc-link.mt-25 {
  margin-top: 25px;
}

.collapsible-view {
  .custom-tab {
    display: flex;
    button {
      width: 100%;
      padding: 10px 10px 6px 10px !important;
    }
  }
}
.apply-job-popup-content {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 325px;
  form {
    .textbox-label {
      text-align: center;
      justify-content: center;
      display: block !important;
      margin: 0 0 8px 0 !important;
      font-size: 15px;
      line-height: 15px;
      font-family: "TTCommons-Regular";
      color: #000485;
    }
    .textbox-with-icon {
      position: relative;
      > img {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        opacity: 0.7;
        &.blur-icon {
          opacity: 1;
        }
      }
      > div {
        margin: 0 0 10px;
      }
      .MuiFormControl-root {
        .MuiInputBase-root {
          input.MuiInputBase-input {
            padding: 21px 27.31px 17.3px 60px;
          }
        }
      }
    }
    .job-upload-cv {
      margin-bottom: 25px;
    }
    .customized-button {
      button {
        font-size: 22px;
      }
    }
    > div {
      z-index: 1;
    }
    .job-upload-cv {
      z-index: 0;
    }
    input.MuiInputBase-input {
      padding: 21px 27.31px 17.3px 25px;
    }
  }
}
.rss-icon-align {
  width: 15px;
  height: 15px;
  margin-bottom: -25px;
  margin-left: 5px;
}
.search-card {
  position: relative;
  .clear-filter {
    position: absolute;
    right: 45px;
    top: 8px;
    text-align: center;
    .text-secondary {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      text-decoration: none;
      border-bottom: 1px solid var(--secondary-color);
    }
  }
}
p.error-text {
  color: var(--secondary-color);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  font-family: "TTCommons-Regular";
}
.popover-wrapper {
  .close-button {
    text-align: center;
    margin-top: 25px;
    button {
      width: 90px;
      height: 30px;
    }
  }
}

.employer-job {
  .MuiAvatar-img {
    object-fit: scale-down !important;
  }
}

.jobs-tutorial__footer-action {
  color: #607790;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
  border-bottom: 1px solid #607790;
}
.student-help-center-avatar {
  border: 1px !important;
}
.jobs-tutorial-dialog-modal {
  .MuiDialogContent-root {
    padding: 28px;
    width: 680px;

    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
      .makeStyles-dialogTitle-8 {
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
        color: #000485;
        max-width: 354px;
      }
      .jobs-tutorial__title {
        max-width: 440px;
      }
      .makeStyles-btnClose-11 {
        top: -5px;
        right: -5px;
      }
    }
    .MuiDialog-container {
      .MuiPaper-root {
        .MuiDialogContent-root {
        }
      }
    }
  }
  .dialog-content {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-bottom: 20px;
    width: auto !important;
  }
}
.jobs-tutorial-dialog-modal__dialog-content {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
  //   scrollbar-color: $color-blue $color-orange;
  // scroll-behavior: smooth;
  // scrollbar-color: $color-grey;
  // scrollbar-width: thin;
  // scrollbar-gutter: stable;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #d1dde5 transparent; /* scroll thumb and track */
  transition: scrollbar-color 0.3s ease-out;
  margin-bottom: 15px;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-track {
  background: #e5eaf7 !important; /* color of the tracking area */
  border-radius: 50px;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-thumb {
  background-color: #bfced8 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 150px !important;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.career-game-plan__wrapper {
  margin-top: 15px !important;
}

.justify-text{
  text-align: justify;
}

.text-color-gray{
  color: #607790 !important;
 }

.faq-answer-text{
  div{
    font-size: 16px !important;
  }
}
.d-none {
  display: none !important;
}
.d-table {
  display: table !important;
}
.flex-2 {
  flex: 2 !important;
}
.w-17 {
  width: 17% !important;
}
.w-11 {
  width: 11% !important;
}
.w-14 {
  width: 14% !important;
}
.w-8 {
  width: 8% !important;
}
.w-4 {
  width: 4% !important;
}

.text-transform-none {
  text-transform: none !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-22 {
  padding-left: 22px !important;
}
.pl-4p {
  padding-left: 4% !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.mt-2p {
  margin-top: 2% !important;
}
