@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";
.e-login-container {
  display: flex;
  align-items: stretch;
  //height: 100vh;
  min-height: 100vh;
  height: auto !important;
  overflow: hidden !important;
  @include tablet() {
    flex-direction: column-reverse;
  }

  &__item {
    flex: 1 1 0;
    width: 0;

    @include tablet() {
      width: 100%;
    }
    .e-instructions {
      @include tablet() {
        margin-top: 20px;
      }
      position: relative;
      width: 100%;
      height: 100%;
      @include tablet() {
        height: 600px;
        padding-left: 15px;
      }
      background-image: url("../../assets/img/e-portal-login-background-cover.svg");
      @include media("1900px") {
        background-size: cover;
      }
      background-size: inherit;
      object-fit: cover;
      background-repeat: no-repeat;
      background-color: $color-brand-blue;

      &__container {
        max-height: 350px;
        max-width: 420px;
        @include media("1900px") {
          max-width: 500px;
        }
        margin: auto;
        position: relative;
        top: 100px;
        .heading {
          font-family: "TTCommons-Regular";
          font-size: 30px;
          font-weight: 800;
          letter-spacing: 0;
          line-height: 28px;
          color: $color-white;
        }
        .subtitle {
          color: $color-white;
          font-family: "TTCommons-Regular";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          margin: 15px 0px 20px 0px;
        }
        .list-items {
          display: flex;
          flex-direction: column;
          &__item {
            display: flex;
            padding: 8px;
            .text {
              color: $color-white;
              font-family: "TTCommons-Regular";
              font-size: 21px;
              letter-spacing: 0;
              line-height: 24px;
              margin-left: 12px;
            }
          }
        }
      }
    }

    .e-login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__logo {
        margin-top: 50px;
        margin-bottom: 25px;
        .brand-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .text {
            font-size: 30px;
            font-family: "TTCommons-Medium";
            color: $color-brand-blue;
            font-weight: 600;
            letter-spacing: 0;

            padding-top: 5px;
          }
        }
      }
      &__heading {
        font-family: "TTCommons-DemiBold";
        font-size: 42px;
        color: $color-brand-blue;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
        max-width: 400px;
      }
      &__item {
        .spacer {
          height: 20px;
          width: 100%;
        }
        max-width: 430px;
        @include media("1900px") {
          max-width: 550px;
        }
        width: 100%;
        margin-top: 25px;
        .e-form {
          &__forgot-password {
            a {
              font-family: "TTCommons-Medium";
              color: $color-brand-blue;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
            }
          }
          &__subtitle {
            font-family: "TTCommons-Medium";
            color: $color-brand-blue;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            font-size: 22px;
            text-align: center;
            padding-top: 0px;
          }
          .form-group {
            max-width: 325px;
            width: 100%;
            margin: auto;

            &__login-button {
              button {
                width: 100%;
                background-color: $color-brand-orange !important;
                border-color: $color-brand-orange !important;
                border-radius: 45px !important;
                height: 60px;
                font-size: 22px !important;
              }
            }
            &__keep-signedin {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 20px auto 30px auto;

              .custom-checkbox {
                height: 20px;
                margin: 0 8px 0 0;
                position: relative;
                input[type="checkbox"] {
                  position: absolute;
                  left: 0;
                  width: 16px;
                  height: 16px;
                  opacity: 0;
                  z-index: 1;
                }
                .checkmark {
                  height: 16px;
                  width: 16px;
                  border: 1px solid $color-brand-blue;
                  border-radius: 100px;
                  display: inline-block;
                  position: relative;
                  img {
                    display: none;
                  }
                }
                input[type="checkbox"]:checked {
                  + .checkmark {
                    background: $color-brand-blue;
                    &:after {
                      content: "";
                      position: absolute;
                      left: 4px;
                      top: 0;
                      width: 5px;
                      height: 9px;
                      border: solid white;
                      border-width: 0 2px 2px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }
                  }
                }
              }
              .label {
                color: $color-brand-blue;
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
              }
            }
            &__signup-btn {
              button {
                border-radius: 45px !important;
                height: 60px;
                font-size: 22px !important;
              }
            }
          }
        }
      }
    }
  }
}
