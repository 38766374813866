.feedback-dialog {
  .MuiDialogContent-root {
    padding: 50px !important;
    box-sizing: border-box;
    .MuiTypography-root {
      padding: 0 !important;
      margin: 0;
    }
    .modal-title {
      width: 100%;
      color: #000485;
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
    }
    .custom-rating {
      > span {
        > span {
          margin: 0 5px;
        }
      }
      .star {
        width: 52px !important;
        height: 52px !important;
        margin: 0 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .model-body {
    max-width: 440px;
    margin: 40px auto 0;
    .rating-box {
      text-align: center;
      margin: 0 0 40px;
    }
    .sub-header-primary {
      margin: 0 0 7px;
    }
    .about-box {
      margin: 0 0 40px;
      label.form-label {
        margin-top: 0;
        margin-bottom: 7px;
      }
      .MuiOutlinedInput-multiline {
        padding: 0;
        margin: 0;
      }

      fieldset {
        display: none;
      }
    }
    .friend-refer {
      .sub-header-primary.title {
        text-align: center;
        margin: 0 0 23px;
      }
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        justify-content: space-between;
        li.point {
          height: 28px;
          width: 28px;
          border: 2px solid #0030b5;
          border-radius: 100px;
          line-height: 27px;
          color: #000485;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          text-align: center;
          margin: 0 0 6px;
          &.active {
            background: #0030b5;
            color: #fff;
          }
        }
      }
    }
    .like {
      display: flex;
      justify-content: space-between;
      margin: 0 0 40px;
      .sub-header-primary {
        margin: 0;
        font-size: 18px;
        line-height: 19px;
      }
      .text-right {
        text-align: right;
      }
      .text-left {
        text-align: left;
      }
    }
    .file-attch {
      display: flex;
      margin: 0 0 49px;
      color: #f76155;
      flex-wrap: wrap;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
        margin: 5px 0 0 0;
      }
      .btn-upload {
        margin: 0 0 0 10.5px;
        // text-decoration: underline;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        border-bottom: 1px solid #f76155;
      }
    }
    .feedback-reply {
      margin: 20px 0px 40px 0px;
      .sub-header-primary {
        color: #000485;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    .btn {
      text-align: center;
      margin: 0 0 30px;
      button {
        box-shadow: none;
        border-radius: 45px;
        height: 60px;
        font-size: 22px;
        line-height: 22px;
        &.got-it {
          height: 30px;
          width: 124px;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
    .text-cancel {
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      text-decoration: none;
      border-bottom: 1px solid #607790;
      cursor: pointer;
    }
  }
}
